import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../common/BreadCrumb'
import PageHeader from '../../common/PageHeader'
import Loader from '../../shared/Loader'
import AxiosHelper from '../../../helper/AxiosHelper'
import { useHistory, useParams } from 'react-router-dom'
import ModalCustom2 from '../../shared/ModalCustom2'
import DonorTop from '../../common/DonorTop'
import moment from 'moment'
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { toast } from 'react-toastify'
import { Alert } from 'react-bootstrap'
import { checkPermission } from '../../middleware/auth'
import ScrollCard from '../../shared/ScrollCard'
import { LabelDivPad } from '../../blood-camp-management/addstaff/StaffForm'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import { useAppContext } from '../../../AppContext'
import UserRolesAutocomplete from '../../shared/UserRoleAutoComplete'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

const bloodGroup = [
  { value: 'AB+Ve', label: 'AB+' },
  { value: 'AB-Ve', label: 'AB-' },
  { value: 'A+Ve', label: 'A+' },
  { value: 'A-Ve', label: 'A-' },
  { value: 'B+Ve', label: 'B+' },
  { value: 'B-Ve', label: 'B-' },
  { value: 'O-Ve', label: 'O-' },
  { value: 'O+Ve', label: 'O+' }
]

export const bloodGroupGet = {
  'AB-Ve': 'AB-',
  'AB+Ve': 'AB+',
  'A+Ve': 'A+',
  'A-Ve': 'A-',
  'B+Ve': 'B+',
  'B-Ve': 'B-',
  'O-Ve': 'O-',
  'O+Ve': 'O+',
  'OH-Ve': 'OH-',
  'OH+Ve': 'OH+'
}

const initial = {
  hiv: '0',
  anty_hcv: '0',
  confirm_blood_group: '',
  malaria: '0',
  vdrl: '0',
  hsbg: '0',
  blood_bag_id: '',
  hivkit: '',
  hiv_method: '',
  vdrl_method: '',
  hepatitisbkit: '',
  hepatitisckit: '',
  syphiliskit: '',
  malariakit: '',
  testing_date: new Date()
}

const TTI = () => {
  const [isLoading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [donorHistory, setDonorHistory] = useState([])
  const [editTab, setEditTab] = useState(true)
  const [formValues, setFormValues] = useState(initial)
  const [todayBag, setTodayBag] = useState({})
  const [show, setShow] = useState(false)
  const [dilague, setDilague] = useState(false)
  const [steps, setSteps] = useState('')
  const [sectionInfo, setSectionInfo] = useState([])
  const [testedBy, setTestedBy] = useState('')
  const [verifiedBy, setVerifiedBy] = useState('')
  //const [ttiTime, setTtiTime] = useState(dayjs())
  const [showError, setShowError] = useState(false)
  const history = useHistory()
  const { id } = useParams()

  const { setActiveTutorial } = useAppContext()

  useEffect(() => {
    setActiveTutorial('donorRegisterationAllProcess')

    return () => {
      setActiveTutorial('')
    }
  }, [])

  const getHistory = async () => {
    setPageLoading(true)
    await AxiosHelper.get(`donor/history/${id}`)
      .then(response => {
        let res = response?.data?.data
        setDonorHistory(res)
        setSteps(res.steps)
        if (res?.is_defered === true) {
          setEditTab(false)
        }
        if (res?.steps === '6') {
          setEditTab(false)
        }
        if (
          res.DonorScreenings &&
          res.DonorScreenings[res.DonorScreenings.length - 1]?.BloodBagIssueEntity?.BloodTestingEntity?.id
        ) {
          setEditTab(false)
        }
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
      })
  }

  const getTodayBag = () => {
    setPageLoading(true)
    AxiosHelper.get(`donor/today-blood-bag/${id}`)
      .then(response => {
        let dataa = response.data.data
        setTodayBag(dataa)
        setPageLoading(false)
        //  setTtiTime(dataa?.assigned_date ? dayjs(dataa?.assigned_date) : dayjs())
        setFormValues({
          ...formValues,
          blood_bag_id: `${dataa.id}`,
          hiv: dataa?.BloodTestingEntity?.hiv || '0',
          anty_hcv: dataa?.BloodTestingEntity?.anty_hcv || '0',
          confirm_blood_group: dataa?.temp_blood_group || '',
          malaria: dataa?.BloodTestingEntity?.malaria || '0',
          vdrl: dataa?.BloodTestingEntity?.vdrl || '0',
          hsbg: dataa?.BloodTestingEntity?.hsbg || '0',
          hivkit: dataa?.BloodTestingEntity?.hivkit || '',
          hiv_method: dataa?.BloodTestingEntity?.hiv_method || '',
          hepatitisbkit: dataa?.BloodTestingEntity?.hepatitisbkit || '',
          hepatitisckit: dataa?.BloodTestingEntity?.hepatitisckit || '',
          syphiliskit: dataa?.BloodTestingEntity?.syphiliskit || '',
          vdrl_method: dataa?.BloodTestingEntity?.vdrl_method || '',
          malariakit: dataa?.BloodTestingEntity?.malariakit || '',
          testing_date: dataa?.BloodTestingEntity?.testing_date || new Date()
        })
        if (dataa) {
          setShow(false)
        }
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
        setShow(true)
      })
  }

  useEffect(() => {
    getHistory()
    getTodayBag()
  }, [])

  useEffect(() => {
    let topinfo = []
    if (donorHistory) {
      topinfo = [
        { label: 'Donor-ID', value: donorHistory?.reg_id || 'N/A' },
        {
          label: 'Mobile',
          value: donorHistory?.mobile ? <>+91 {donorHistory?.mobile}</> : 'N/A'
        },
        {
          label: 'Donation Date',
          value: donorHistory?.donation_date ? moment(donorHistory?.donation_date).format('DD-MMM-YYYY') : 'N/A'
        },
        { label: 'ABHA Id', value: donorHistory?.abha_id || 'N/A' }
      ]
    }
    setSectionInfo(topinfo)
  }, [donorHistory])

  const breadcrumbRoute = [
    {
      path: '/donors',
      label: 'Donors'
    },
    {
      path: '/donors-detail/' + donorHistory?.id,
      label: donorHistory?.name
    },
    {
      path: '',
      label: 'TTI'
    }
  ]

  const ttiData = [
    {
      value: todayBag?.bag_id || '',
      label: 'Blood Bag Number'
    },
    {
      value: todayBag?.BloodTestingEntity?.hiv
        ? todayBag?.BloodTestingEntity?.hsbg === '0'
          ? 'Non-Reactive'
          : 'Reactive'
        : '',
      label: 'HIV'
    },
    {
      value: todayBag?.BloodTestingEntity?.anty_hcv
        ? todayBag?.BloodTestingEntity?.hsbg === '0'
          ? 'Non-Reactive'
          : 'Reactive'
        : '',
      label: 'Anti-HCV(Hepatitis C)'
    },
    {
      value: todayBag?.BloodTestingEntity?.malaria
        ? todayBag?.BloodTestingEntity?.malaria === '0'
          ? 'Negative'
          : 'Positive'
        : '',
      label: 'Malaria By Card Test'
    },
    {
      value: todayBag?.BloodTestingEntity?.vdrl
        ? todayBag?.BloodTestingEntity?.vdrl === '0'
          ? 'Negative'
          : 'Positive'
        : '',
      label: 'Syphilis(VDRL'
    },
    {
      value: todayBag?.BloodTestingEntity?.hsbg
        ? todayBag?.BloodTestingEntity?.hsbg === '0'
          ? 'Non-Reactive'
          : 'Reactive'
        : '',
      label: 'HBsAg(Hepatitis B)'
    }
  ]

  const testingKitData = [
    {
      value: todayBag?.BloodTestingEntity?.hivkit || '',
      label: 'HIV Kit'
    },
    {
      value: todayBag?.BloodTestingEntity?.hepatitisbkit || '',
      label: 'Hepatitis-B Kit'
    },
    {
      value: todayBag?.BloodTestingEntity?.hepatitisckit || '',
      label: ' Hepatitis-C Kit'
    },
    {
      value: todayBag?.BloodTestingEntity?.syphiliskit || '',
      label: 'Syphilis (VDRL) Kit'
    },
    {
      value: todayBag?.BloodTestingEntity?.malariakit || '',
      label: 'Malaria Kit'
    }
  ]

  const testingMethodData = [
    {
      value: todayBag?.BloodTestingEntity?.hiv_method || '',
      label: 'HIV Method'
    },
    {
      value: todayBag?.BloodTestingEntity?.hepatitisb_method || '',
      label: 'Hepatitis-B Method'
    },
    {
      value: todayBag?.BloodTestingEntity?.hepatitisc_method || '',
      label: ' Hepatitis-C Method'
    },
    {
      value: todayBag?.BloodTestingEntity?.vdrl_method || '',
      label: 'Syphilis (VDRL) Method'
    },
    {
      value: todayBag?.BloodTestingEntity?.malaria_method || '',
      label: 'Malaria Method'
    }
  ]

  const handleChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    })
  }
  const handlefinalSubmit = () => {
    setLoading(true)

    let allTestValues = {
      ...formValues,
      hivkit: formValues.hivkit,
      hepatitisbkit: formValues?.hivkit,
      hepatitisckit: formValues?.hivkit,
      syphiliskit: formValues?.syphiliskit,
      malariakit: formValues?.syphiliskit,
      hiv_method: formValues?.hiv_method,
      hepatitisb_method: formValues?.hiv_method,
      hepatitisc_method: formValues?.hiv_method,
      vdrl_method: formValues?.vdrl_method,
      malaria_method: formValues?.vdrl_method,
      testing_date: formValues?.testing_date
    }

    let data = { tested_by: testedBy, verified_by: verifiedBy, data: [allTestValues] }

    AxiosHelper.post('blood-testing', data)
      .then(response => {
        getTodayBag()
        getHistory()
        toast.success('TTI Testing Done successfully!')
        setLoading(false)
        setEditTab(false)
        history.push(`/donors-detail/donor-inventory/${id}`)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
        toast.error(error || 'Something went wrong our side!')
      })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!testedBy || !verifiedBy) {
      setShowError(true)
      return
    }
    setDilague(true)
    //handlefinalSubmit();
  }
  const closeDialog = () => {
    setDilague(false)
  }
  const confirmDialog = () => {
    setDilague(false)
    handlefinalSubmit()
  }

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute}>
        <PageHeader title='TTI' />
      </BreadCrumb>

      <ScrollCard>
        <form onSubmit={handleSubmit}>
          {dilague ? (
            <ModalCustom2
              children={"Please review again before final submission. Once you submit, you can't edit."}
              open={dilague}
              confirmDialog={confirmDialog}
              closeDialog={closeDialog}
            />
          ) : null}
          {/* <ProfileTop info={donorHistory} /> */}
          <DonorTop info={sectionInfo} basicInfo={donorHistory} />

          <Alert
            variant='info'
            show={show}
            onClose={() => setShow(false)}
            // dismissible
          >
            <Alert.Heading>Blood Bag Not Assigned</Alert.Heading>
            <p>No Blood Bag assigned to test</p>
          </Alert>
          <div className='bloodbank_action  '>
            <p>TTI Information</p>
            {editTab ? null : (
              <>
                {steps === '6' ? (
                  <div className='d-flex' style={{ gap: '10px' }}>
                    <button
                      type='button'
                      onClick={e => history.push(`/donors-detail/donor-inventory/${id}`)}
                      className='btn gradient-btn'
                    >
                      Go to Donor inventory
                    </button>
                  </div>
                ) : null}{' '}
              </>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem'
            }}
          >
            <LabelDivPad title={editTab ? 'Select Testing Kit' : '' + 'Testing Kit Information'}>
              {!editTab ? (
                <Grid container spacing={1.5}>
                  {testingMethodData?.map((item, i) => (
                    <Grid key={i} item xs={6} sm={4} md={3} lg={2.4}>
                      <Typography variant='body1' fontWeight={600} fontSize='1rem'>
                        {item?.label}
                        <Typography>{item?.value || 'N/A'}</Typography>
                      </Typography>
                    </Grid>
                  ))}
                  {testingKitData?.map((item, i) => (
                    <Grid key={i} item xs={6} sm={4} md={3} lg={2.4}>
                      <Typography variant='body1' fontWeight={600} fontSize='1rem'>
                        {item?.label}
                        <Typography>{item?.value || 'N/A'}</Typography>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <>
                  <Grid container spacing={1.5}>
                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Method for (HCV/HIV/HBsAg)
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Autocomplete
                          sx={{
                            '&.MuiAutocomplete-noOptions': {
                              padding: 0
                            }
                          }}
                          options={['ELISA', 'Rapid']}
                          value={formValues?.hiv_method}
                          renderInput={params => (
                            <TextField required {...params} placeholder='Select Kit' sx={inputStyles} />
                          )}
                          onChange={(event, value) => {
                            setFormValues({ ...formValues, hiv_method: value })
                          }}
                        />
                      </FormControl>
                      {/* <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          HIV Method
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='hivkit'
                          value={formValues?.hivkit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          <MenuItem value='ELISA'>ELISA</MenuItem>
                          <MenuItem value='Rapid'>Rapid</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>

                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Kit for (HCV/HIV/HBsAg)
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Autocomplete
                          sx={{
                            '&.MuiAutocomplete-noOptions': {
                              padding: 0
                            }
                          }}
                          options={[
                            'Qualisa',
                            'Merilisa',
                            'Oscar',
                            'Makesure',
                            'Erba Lisa',
                            'Q-Lisa',
                            'Trust well',
                            'Meril','Abbott','Hepa'
                          ]}
                          value={formValues?.hivkit}
                          renderInput={params => (
                            <TextField required {...params} placeholder='Select Kit' sx={inputStyles} />
                          )}
                          onChange={(event, value) => {
                            setFormValues({ ...formValues, hivkit: value })
                          }}
                        />
                      </FormControl>

                      {/* <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Hepatitis-B Method
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          fullWidth
                          sx={{ height: 45, background: '#f5f5f5' }}
                          InputProps={{
                            sx: {
                              minWidth: 150
                            }
                          }}
                          name='hepatitisbkit'
                          value={formValues?.hepatitisbkit}
                          onChange={handleChange}
                          size='small'
                          placeholder=''
                        >
                          <MenuItem value='ELISA'>ELISA</MenuItem>
                          <MenuItem value='Rapid'>Rapid</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Method for (VDRL/Malaria)
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Autocomplete
                          sx={{
                            '&.MuiAutocomplete-noOptions': {
                              padding: 0
                            }
                          }}
                          options={['ELISA', 'Rapid']}
                          renderInput={params => (
                            <TextField required {...params} placeholder='Select Kit' sx={inputStyles} />
                          )}
                          value={formValues?.vdrl_method}
                          onChange={(event, value) => {
                            setFormValues({ ...formValues, vdrl_method: value })
                          }}
                        />
                      </FormControl>

                      {/* <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Hepatitis-C Method
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='hepatitisckit'
                          value={formValues?.hepatitisckit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          <MenuItem value='ELISA'>ELISA</MenuItem>
                          <MenuItem value='Rapid'>Rapid</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>

                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Kit for (VDRL/Malaria)
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Autocomplete
                          options={['General', 'RPR Kits','Aspin','Trust Well']}
                          renderInput={params => (
                            <TextField required {...params} placeholder='Select Kit' sx={inputStyles} />
                          )}
                          value={formValues?.syphiliskit}
                          onChange={(event, value) => {
                            setFormValues({ ...formValues, syphiliskit: value })
                          }}
                        />
                      </FormControl>
                      {/* <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Syphilis (VDRL) Kit
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='syphiliskit'
                          value={formValues?.syphiliskit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          <MenuItem value='ELISA'>ELISA</MenuItem>
                          <MenuItem value='Rapid'>Rapid</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Testing Date/Time
                          <span className='text-danger'>*</span>
                        </FormLabel>

                        <DateTimePicker
                          sx={inputStyles}
                          format={'DD/MM/YYYY HH:mm'}
                          value={dayjs(formValues?.testing_date)}
                          onChange={value => {
                            setFormValues({ ...formValues, testing_date: value })
                          }}
                          slotProps={{
                            textField: params => ({
                              ...params,
                              required: true,
                              placeholder: 'Select Date & Time',
                              size: 'small',
                              fullWidth: true
                            })
                          }}
                        />
                      </FormControl>
                      {/* <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Malaria Method
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='malariakit'
                          value={formValues?.malariakit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          <MenuItem value='ELISA'>ELISA</MenuItem>
                          <MenuItem value='Rapid'>Rapid</MenuItem>
                        </Select>
                      </FormControl> */}
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={1.5}>
                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          HIV Kit
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='hivkit'
                          value={formValues?.hivkit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          {optionsKit()}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Hepatitis-B Kit
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          fullWidth
                          sx={{ height: 45, background: '#f5f5f5' }}
                          InputProps={{
                            sx: {
                              minWidth: 150
                            }
                          }}
                          name='hepatitisbkit'
                          value={formValues?.hepatitisbkit}
                          onChange={handleChange}
                          size='small'
                          placeholder=''
                        >
                          {optionsKit()}

                          <MenuItem value='Rapid Kit'>Rapid Kit</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Hepatitis-C Kit
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='hepatitisckit'
                          value={formValues?.hepatitisckit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          {optionsKit()}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Syphilis (VDRL) Kit
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='syphiliskit'
                          value={formValues?.syphiliskit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          <MenuItem value='General'>General</MenuItem>
                          <MenuItem value='RPR Kits'>RPR Kits</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4} md={3} lg={2.4}>
                      <FormControl fullWidth>
                        <FormLabel sx={labelStyles}>
                          Malaria Kit
                          <span className='text-danger'>*</span>
                        </FormLabel>
                        <Select
                          required
                          disabled={show}
                          name='malariakit'
                          value={formValues?.malariakit}
                          onChange={handleChange}
                          sx={{ height: 45, background: '#f5f5f5' }}
                          size='small'
                          fullWidth
                        >
                          <MenuItem value='General'>General</MenuItem>
                          <MenuItem value='RPR Kits'>RPR Kits</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid> */}
                </>
              )}
            </LabelDivPad>
            <LabelDivPad title='TTI Information'>
              {!editTab ? (
                <Grid container spacing={1.5}>
                  {ttiData?.map((item, i) => (
                    <Grid key={i} item xs={6} sm={4} md={3} lg={2.4}>
                      <Typography variant='body1' fontWeight={600} fontSize='1rem'>
                        {item?.label}
                        <Typography>{item?.value || 'N/A'}</Typography>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={1.5}>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        Blood Bag Number
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      <TextField
                        disabled
                        fullWidth
                        InputProps={{
                          sx: {
                            height: 45,
                            background: '#f5f5f5'
                          }
                        }}
                        name='blood_bag_id'
                        value={todayBag?.bag_number}
                        onChange={handleChange}
                        size='small'
                        required
                        placeholder='Enter Bag number'
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        Blood Group
                        <span className="text-danger">*</span>
                      </FormLabel>
                      <Select
                        required
                        disabled={show}
                        name="confirm_blood_group"
                        value={formValues?.confirm_blood_group}
                        onChange={handleChange}
                        sx={{ height: 45, background: "#f5f5f5" }}
                        size="small"
                        fullWidth
                      >
                        {bloodGroup?.map((item, index) => (
                          <MenuItem value={item.value}>{item?.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        HIV
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      <Select
                        required
                        disabled={show}
                        fullWidth
                        sx={{ height: 45, background: '#f5f5f5' }}
                        InputProps={{
                          sx: {
                            minWidth: 150
                          }
                        }}
                        name='hiv'
                        value={formValues?.hiv}
                        onChange={handleChange}
                        size='small'
                        placeholder=''
                      >
                        <MenuItem value='0'>{formValues?.hivkit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}</MenuItem>
                        <MenuItem value='1'>{formValues?.hivkit == 'ELISA Kit' ? 'Reactive' : 'Positive'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        Malaria By Card Test
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      <Select
                        required
                        disabled={show}
                        name='malaria'
                        value={formValues?.malaria}
                        onChange={handleChange}
                        sx={{ height: 45, background: '#f5f5f5' }}
                        size='small'
                        fullWidth
                      >
                        <MenuItem value='0'>
                          {formValues?.malariakit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}
                        </MenuItem>
                        <MenuItem value='1'>{formValues?.malariakit == 'ELISA Kit' ? 'Reactive' : 'Positive'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        HBsAg(Hepatitis B)
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      <Select
                        required
                        disabled={show}
                        name='hsbg'
                        value={formValues?.hsbg}
                        onChange={handleChange}
                        sx={{ height: 45, background: '#f5f5f5' }}
                        size='small'
                        fullWidth
                      >
                        <MenuItem value='0'>
                          {formValues?.hepatitisbkit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}
                        </MenuItem>
                        <MenuItem value='1'>
                          {formValues?.hepatitisbkit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        Anti-HCV(Hepatitis C)
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      <Select
                        required
                        disabled={show}
                        name='anty_hcv'
                        value={formValues?.anty_hcv}
                        onChange={handleChange}
                        sx={{ height: 45, background: '#f5f5f5' }}
                        size='small'
                        fullWidth
                      >
                        <MenuItem value='0'>
                          {formValues?.hepatitisckit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}
                        </MenuItem>
                        <MenuItem value='1'>
                          {formValues?.hepatitisckit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        Syphilis (VDRL)
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      <Select
                        required
                        disabled={show}
                        name='vdrl'
                        value={formValues?.vdrl}
                        onChange={handleChange}
                        sx={{ height: 45, background: '#f5f5f5' }}
                        size='small'
                        fullWidth
                      >
                        <MenuItem value='0'>
                          {formValues?.syphiliskit == 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}
                        </MenuItem>
                        <MenuItem value='1'>
                          {formValues?.syphiliskit == 'ELISA Kit' ? 'Reactive' : 'Positive'}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </LabelDivPad>
            <LabelDivPad title='TTI Testing Done By'>
              {!editTab ? (
                <Grid container spacing={1.5}>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <Typography variant='body1' fontWeight={600} fontSize='1rem'>
                      {'Technician Name'}
                      <Typography>{todayBag?.BloodTestingEntity?.tested_by || 'N/A'}</Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <Typography variant='body1' fontWeight={600} fontSize='1rem'>
                      {'Verified By'}
                      <Typography>{todayBag?.BloodTestingEntity?.verified_by || 'N/A'}</Typography>
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1.5}>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        Technician Name
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      {/* <TextField
                        fullWidth
                        InputProps={{
                          sx: {
                            height: 45,
                            background: '#f5f5f5'
                          }
                        }}
                        name='tested_by'
                        value={testedBy}
                        onChange={e => {
                          setTestedBy(e.target.value)
                        }}
                        size='small'
                        required
                        placeholder='Enter Technician Name'
                      /> */}
                      <UserRolesAutocomplete
                        value={testedBy}
                        onChange={(e, val) => {
                          setTestedBy(val)
                        }}
                      />
                      {showError && !testedBy ? (
                        <FormHelperText sx={{ color: 'error.main' }}>{'Technician Name is required'}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        Verified By
                        <span className='text-danger'>*</span>
                      </FormLabel>

                      <UserRolesAutocomplete
                        value={verifiedBy}
                        onChange={(e, val) => {
                          setVerifiedBy(val)
                        }}
                        required
                      />
                      {showError && !verifiedBy ? (
                        <FormHelperText sx={{ color: 'error.main' }}>{'Verified by is required'}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={6} sm={4} md={3} lg={2.4}>
                    <FormControl fullWidth>
                      <FormLabel sx={labelStyles}>
                        TTI Date and Time
                        <span className='text-danger'>*</span>
                      </FormLabel>
                      <DateTimePicker
                        ampm={false}
                        size='small'
                        fullWidth
                        slotProps={{
                          textField: {
                            InputProps: { style: { height: 40 } }, // Set height for the input field
                          },
                        }}
                        value={ttiTime}
                        onChange={(e, val) => { setTtiTime(dayjs(val)) }}
                        required
                      />
                    </FormControl>
                  </Grid> */}
                </Grid>
              )}
            </LabelDivPad>
          </div>

          {editTab ? (
            <div className='d-flex mt-3' style={{ gap: '10px' }}>
              <button
                type='button'
                style={{
                  cursor: show ? 'not-allowed' : 'pointer'
                }}
                disabled={show}
                onClick={() => {
                  setFormValues(initial)
                  // getTodayBag();
                  // setEditTab(false)
                }}
                className='btn backBtn '
              >
                Reset
              </button>
              {checkPermission('UPDATE_TTI_TESTING') && (
                <button
                  style={{
                    cursor: show ? 'not-allowed' : 'pointer'
                  }}
                  type='submit'
                  disabled={isLoading || show}
                  className='btn gradient-btn'
                >
                  {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
                </button>
              )}
            </div>
          ) : null}
        </form>
      </ScrollCard>
    </div>
  )
}

export default TTI
