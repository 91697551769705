import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import AxiosHelper from '../../../helper/AxiosHelper'
import { useHistory, useParams } from 'react-router-dom'
import { LabelDiv } from '../../screening/ScreeningDonorDetails'
import FormProvider from '../../shared/FormProvider'
import RHFTextField from '../../shared/RHFTextfield'
import RHFSelect from '../../shared/RHFSelect'
import { RHFDatePicker } from '../../shared/RHFDatePicker'
import dayjs from 'dayjs'
import { radioStyles } from '../addBloodCamp/AddBloodCamp'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Chip,
  Checkbox,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material'

import * as yup from 'yup'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Add, Close } from '@mui/icons-material'
import { inputStyles, labelStyles } from '../../certification/EditCertificate'
import { LetterMessage } from '../../blood-requisition/AddBloodRequest'
import TextButton from '../../shared/components/TextButton'
import { checkPermission } from '../../middleware/auth'
import RHFUserRolesAutocomplete from '../../shared/RHFUserRolesAutocomplete'
import UserRolesAutocomplete from '../../shared/UserRoleAutoComplete'
import { getLocalStorageItem } from '../../../helper/CommonAPI'

export const LabelDivPad = ({ title, children, style }) => {
  return (
    <div style={style} className='reg-box-staff w-100 position-relative '>
      {title ? <p className='div_label div_label_text'>{title}</p> : null}
      {children}
    </div>
  )
}

const initialComp = {
  name: '',
  role: [],
  mobile: '',
  validity: '',
  vehicle_no: '',
  personal_titles: 'Mr.'
}

const duties = [
  'MO IC',
  'Helper',
  'Sr.Technical Supervisor',
  'Office Boy',
  'Sr. Technician',
  'PRO',
  'MO',
  'Nurse',
  'Technician',
  'Counsellor',
  'Driver',
  'Camp Incharge',
  'Material Return Incharge'
]

const defaultValues = {
  staff: [initialComp]
}

const letters = /^[a-zA-Z. ]*$/

const schema = yup.object().shape({
  staff: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name is required').matches(letters, 'Invalid name'),
      personal_titles: yup.string(),
      role: yup.array().min(1, 'Role is required'),
      // .required("Role is required"),
      //.max(50, LetterMessage),
      mobile: yup.string().required('mobile is required').max(10, 'Mobile number must have 10 digit')
    })
  )
})

const StaffForm = ({ state = 'Add', allStaff, setEditTab, editId, camp, addFromView, getCampDetails }) => {
  const [isLoading, setLoading] = useState(false)
  const [isnumRepeat, setIsNumRepeat] = useState(false)
  const history = useHistory()
  let { id } = useParams()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  // ** Hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    clearErrors,
    setError,
    formState: { errors }
  } = methods

  const handleAddStaff = data => {
    console.log('data', data)
    if (isnumRepeat) {
      return
    }

    let bodyData = {
      staff: data?.staff
    }
    let url
    if (state === 'Edit') {
      url = `camp/edit-staff/${id || editId}`

      AxiosHelper.put(url, bodyData)
        .then(response => {
          setLoading(false)
          toast.success(`Staff ${state === 'Edit' ? 'Updated' : 'Added'} Successfully`)
          if (editId) {
            getCampDetails()
          }
          if (camp) {
            setEditTab(false)
          } else {
            history.push(`/blood-camp-management/staff/${id || editId}`)
          }
        })
        .catch(error => {
          console.log(error)
          toast.error(error)
          setLoading(false)
        })
      return
    } else {
      url = 'camp/add-staff'
      bodyData.camp_id = id || editId
    }
    console.log(bodyData)
    setLoading(true)
    AxiosHelper.post(url, bodyData)
      .then(response => {
        setLoading(false)
        toast.success(`Staff ${state === 'Edit' ? 'Updated' : 'Added'} Successfully`)
        if (addFromView) {
          getCampDetails()
        } else {
          history.push(`/blood-camp-management/staff/${id || editId}`)
        }
      })
      .catch(error => {
        console.log(error)
        toast.error(error)
        setLoading(false)
      })
  }

  const addComponent = () => {
    const staff = [...watch('staff')]
    staff.push(initialComp)
    setValue('staff', staff)
  }

  const removeItem = index => {
    const staff = [...watch('staff')]
    staff.splice(index, 1)
    if (staff?.length === 0) {
      staff.push({
        mobile: '',
        name: '',
        role: [],
        vehicle_no: '',
        validity: '',
        personal_titles: ''
      })
    }

    setValue('staff', staff)
  }

  useEffect(() => {
    if (allStaff?.length) {
      let newArray = allStaff?.map(item => {
        return {
          ...item,
          role: JSON.parse(item?.role),
          validity: dayjs(item?.validity)
        }
      })
      setValue('staff', newArray)
    }
  }, [allStaff])

  function areNumbersUnique(arr) {
    const seen = {}
    for (const num of arr) {
      if (seen[num]) {
        return false // If the number has already been seen, it's not unique
      }
      seen[num] = true // Mark the number as seen
    }
    return true
  }

  const handleMobNumber = (e, index) => {
    // setValue(`staff.${index}.mobile`, e.target.value);
    const arr = getValues('staff')
    console.log(arr)
    if (arr.length > 1) {
      let mobarr = []
      arr.forEach((ele, i) => {
        mobarr.push(ele.mobile)
      })
      let a = areNumbersUnique(mobarr)
      console.log(a)
      if (a) {
        clearErrors(`staff.${index}.mobile`)
      } else {
        console.log('hello', index)
        setError(`staff.${index}.mobile`, {
          type: 'custom',
          message: "Mobile number can't be same"
        })
      }
      setIsNumRepeat(!a)
    }
  }
  // const FillStaffDetails = (staffname) => {
  //   console.log("namee", staffname);

  //   if (staffname.length > 0) {
  //     staffname.forEach((item, index) => {
  //       setValue(`staff.${index}.mobile`, item.mobile || ""); 
  //       setValue(`staff.${index}.role`, item.role || ""); 
  //       setValue(`staff.${index}.vehicle_no`, item.vehicle_no || ""); 
  //       setValue(`staff.${index}.personal_titles`, item.personal_titles || ""); 
  //     });
  //   }
  // };

  // const staff = useWatch({ control, name: "staff" });

  // useEffect(() => {
  //   if (!staff || staff.length === 0) return;

  //   staff.forEach((item, index) => {
  //     if (!item?.mobile) setValue(`staff.${index}.mobile`, item.mobile || "");
  //     if (!item?.role) setValue(`staff.${index}.role`, item.role || "");
  //     if (!item?.vehicle_no) setValue(`staff.${index}.vehicle_no`, item.vehicle_no || "");
  //     if (!item?.personal_titles) setValue(`staff.${index}.personal_titles`, item.personal_titles || "");
  //   });
  // }, [staff]);
  // const handleNameChange = (role, i) => {
  //   console.log('val', role, i)
  //   try {
  //     const auth = getLocalStorageItem('authInfo', '[]')
  //     const users = JSON.parse(auth)
  //     const allUsers = users?.users || []
  //     if (!Array.isArray(allUsers)) {
  //       throw new Error('Invalid users data')
  //     }

  //     const structuredUsers = allUsers.flatMap(user =>
  //       user.UserDetailsEntities.filter((detail, i) => ({
  //         detail.UserEntity?.name === role
  //       }))
  //     )

  //     if (role) {
  //       const filteredUsers = structuredUsers.filter(user => user.name === role)
  //       console.log('filter', filteredUsers)
  //       if(filteredUsers){
  //          return( setValue(`staff.${i}.mobile`, filteredUsers.mobile || ""),
  //          setValue(`staff.${i}.role`, filteredUsers.role || ""),
  //          setValue(`staff.${i}.vehicle_no`, filteredUsers.vehicle_no || ""),
  //          setValue(`staff.${i}.personal_titles`, filteredUsers.personal_titles || ""))
  //       } 
  //     } else {
  //       return structuredUsers
  //     }
  //   } catch (error) {
  //     console.error('Error fetching users by role:', error)
  //     return []
  //   }
  // }
  return (
    <div>
      <div className='my-auto'>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleAddStaff)}>
          <div className='bloodbank_action'>
            <p>{state} Staff</p>
          </div>

          <div>
            <Grid container spacing={3}>
              {watch('staff')?.map((field, index) => (
                <>
                  <Grid item xs={11}>
                    <LabelDivPad>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel sx={labelStyles}>
                            Enter Staff Name
                            <span className='text-danger'>*</span>
                          </FormLabel>
                          {/* <UserRolesAutocomplete
                            value={watch(`staff.${index}.name`)}
                            name={`staff.${index}.name`}
                            onChange={(e, val) => {
                              setValue(`staff.${index}.name`, val)
                              handleNameChange(val, index)
                            }}
                            inputHeight={45}
                          /> */}
                          <RHFUserRolesAutocomplete
                            name={`staff.${index}.name`}
                            size='small'
                            fullWidth
                            sx={inputStyles}
                            startAdornment={
                              <Box
                                sx={{
                                  borderRight: '2px solid #d1d5db',
                                  mr: 1
                                }}
                              >
                                <Select
                                  // size='small'
                                  sx={{
                                    overflow: 'hidden',
                                    maxWidth: '60px'
                                  }}
                                  variant='standard'
                                  disableUnderline
                                  value={watch(`staff.${index}.personal_titles`)}
                                  onChange={e => {
                                    setValue(`staff.${index}.personal_titles`, e.target.value)
                                  }}
                                >
                                  <MenuItem value='Dr.'>Dr.</MenuItem>
                                  <MenuItem value='Mr.'>Mr.</MenuItem>
                                  <MenuItem value='Ms.'>Ms.</MenuItem>
                                  <MenuItem value='Mrs.'>Mrs.</MenuItem>
                                </Select>
                              </Box>}
                          />
                          {/* <RHFTextField
                            name={`staff.${index}.name`}
                            size='small'
                            fullWidth
                            sx={inputStyles}
                            InputProps={{
                              startAdornment: (
                                <Box
                                  sx={{
                                    borderRight: '2px solid #d1d5db',
                                    mr: 1
                                  }}
                                >
                                  <Select
                                    size='small'
                                    sx={{
                                      overflow: 'hidden',
                                      maxWidth: '60px'
                                    }}
                                    variant='standard'
                                    disableUnderline
                                    value={watch(`staff.${index}.personal_titles`)}
                                    onChange={e => {
                                      setValue(`staff.${index}.personal_titles`, e.target.value)
                                    }}
                                  >
                                    <MenuItem value='Dr.'>Dr.</MenuItem>
                                    <MenuItem value='Mr.'>Mr.</MenuItem>
                                    <MenuItem value='Ms.'>Ms.</MenuItem>
                                    <MenuItem value='Mrs.'>Mrs.</MenuItem>
                                  </Select>
                                </Box>
                              )
                            }}
                            // InputProps={InputEndProps}
                          /> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel sx={labelStyles}>
                            Enter Role
                            <span className='text-danger'>*</span>
                          </FormLabel>
                          <Select
                            multiple
                            fullWidth
                            sx={{
                              ...inputStyles
                              // "& .MuiInputBase-root": {
                              //   height: "49px !important",
                              // },
                            }}
                            size='small'
                            // label="Users"
                            value={watch(`staff.${index}.role`)}
                            onChange={e => setValue(`staff.${index}.role`, e.target.value)}
                            labelId='user-select'
                            renderValue={selected => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5
                                }}
                              >
                                {selected.map((value, i) => {
                                  return <Chip size='small' key={value} label={value} />
                                })}
                              </Box>
                            )}
                          >
                            <MenuItem value=''>
                              <Checkbox
                                size='small'
                                onChange={e => {
                                  if (e.target.checked) {
                                    setValue(`staff.${index}.role`, duties)
                                  } else {
                                    setValue(`staff.${index}.role`, [])
                                  }
                                }}
                                checked={watch(`staff.${index}.role`)?.length === duties?.length}
                              />
                              Select All
                            </MenuItem>
                            {duties?.length > 0 ? (
                              duties.map((user, i) => (
                                <MenuItem key={i} value={user}>
                                  <Checkbox
                                    sx={radioStyles}
                                    size='small'
                                    checked={watch(`staff.${index}.role`).indexOf(user) !== -1}
                                  />

                                  {user}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value=''>No Duty Found</MenuItem>
                            )}
                          </Select>
                          {console.log('errors', errors)}
                          {/* {console.log(errors && errors?.staff[index]?.role)} */}
                          {errors && errors.staff && errors.staff[index] && errors.staff[index].role && (
                            <FormHelperText sx={{ color: 'error.main' }}>
                              {errors.staff[index].role.message}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormLabel sx={labelStyles}>
                            Mobile number
                            <span className='text-danger'>*</span>
                          </FormLabel>
                          <RHFTextField
                            name={`staff.${index}.mobile`}
                            size='small'
                            type='number'
                            fullWidth
                            onChange={e => {
                              handleMobNumber(e, index)
                            }}
                            sx={inputStyles}
                            InputProps={{
                              startAdornment: (
                                <Box
                                  sx={{
                                    borderRight: '2px solid #d1d5db',
                                    mr: 1
                                  }}
                                >
                                  <Select size='small' variant='standard' disableUnderline value='+91'>
                                    <MenuItem value='+91'>+91</MenuItem>
                                  </Select>
                                </Box>
                              )
                            }}
                          // InputProps={InputEndProps}
                          />

                          {/* <Select
                          multiple
                          fullWidth
                          sx={{
                            ...inputStyles,
                            "& .MuiInputBase-root": {
                              height: "44px !important",
                            },
                          }}
                          size="small"
                          // label="Users"
                          value={watch(`staff.${index}.duty`)}
                          onChange={(e) =>
                            setValue(`staff.${index}.duty`, e.target.value)
                          }
                          labelId="user-select"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => {
                                return (
                                  <Chip
                                    size="small"
                                    key={value}
                                    label={value}
                                  />
                                );
                              })}
                            </Box>
                          )}
                        >
                          <MenuItem value="">
                            <Checkbox
                              size="small"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setValue(`staff.${index}.duty`, duties);
                                } else {
                                  setValue(`staff.${index}.duty`, []);
                                }
                              }}
                              checked={
                                watch(`staff.${index}.duty`).length ===
                                duties?.length
                              }
                            />
                            Select All
                          </MenuItem>
                          {duties?.length > 0 ? (
                            duties.map((user, i) => (
                              <MenuItem key={i} value={user}>
                                <Checkbox
                                  size="small"
                                  checked={
                                    watch(`staff.${index}.duty`).indexOf(
                                      user
                                    ) !== -1
                                  }
                                />

                                {user}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">No Duty Found</MenuItem>
                          )}
                        </Select> */}
                          {/* {console.log('errors',errors)} */}
                          {/* {errors?.staff[index]?.duty ? (
                          <FormHelperText sx={{ color: 'error.main' }} >
                            {errors?.staff[index]?.duty?.message}
                          </FormHelperText>
                        ) : null} */}
                        </Grid>

                        {watch(`staff.${index}.role`).includes('Driver') && (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormLabel sx={labelStyles}>
                                Enter Vehicle/Ambulance No.
                                <span className='text-danger'>*</span>
                              </FormLabel>
                              <RHFTextField
                                name={`staff.${index}.vehicle_no`}
                                size='small'
                                fullWidth
                                sx={inputStyles}
                              // InputProps={InputEndProps}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormLabel sx={labelStyles}>
                                Certificate Valied Till
                                <span className='text-danger'>*</span>
                              </FormLabel>
                              <RHFDatePicker name={`staff.${index}.validity`} fullWidth sx={inputStyles} />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </LabelDivPad>
                  </Grid>
                  <Grid item xs={1}>
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }}
                    >
                      <IconButton
                        color='error'
                        sx={{
                          display: watch('staff')?.length > 1 ? 'flex' : 'none',
                          width: 'fit-content',
                          borderRadius: '8px'
                        }}
                        onClick={() => removeItem(index)}
                      >
                        <Close icon='material-symbols:close' />
                      </IconButton>
                    </Box>
                  </Grid>
                </>
              ))}
              <Grid display='flex' justifyContent='flex-end' sx={{ mt: '-0.5rem' }} item xs={12} sm={11}>
                <TextButton onClick={addComponent}>
                  <Add /> Add More
                </TextButton>
                {/* <button className="btn gradient-btn" onClick={addComponent}>
                </button> */}
              </Grid>
              {checkPermission('UPDATE_CAMP') === true ? (
                <Grid item xs={12}>
                  <Typography sx={{ pb: 2 }} color='error'>
                    {isnumRepeat ? 'Mobile numbers cannot be same' : ''}
                  </Typography>
                  <div className='btn-container'>
                    <button
                      type='button'
                      className='btn backBtn'
                      onClick={() => {
                        state === 'Edit' ? setEditTab(false) : history.push('/blood-camp-management')
                      }}
                    >
                      Cancel
                    </button>
                    <button disabled={isLoading} type='submit' className='btn gradient-btn '>
                      {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
                    </button>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </FormProvider>
      </div>
    </div>
  )
}

export default StaffForm
