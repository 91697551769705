export const inputBoxStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },
  '& .MuiInputBase-input': {
    height: 30
  },
  '& .MuiInputBase-root': {
    height: 40,
    overflow: 'hidden'
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

export const dangerTextStyles = {
  padding: '4px 8px',
  borderRadius: '8px',
  fontWeight: 'bold',
  width: 'max-content',
  color: '#a61d24',
  backgroundColor: '#fdecea'
}