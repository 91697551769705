import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { FormHelperText, FormLabel, Grid, Checkbox, CircularProgress, MenuItem, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { RHFDatePicker } from '../../../shared/RHFDatePicker'
import AxiosHelper from '../../../../helper/AxiosHelper'
import RHFTextField from '../../../shared/RHFTextfield'
import { inputStyles, labelStyles } from '../../../certification/EditCertificate'
import FormProvider from '../../../shared/FormProvider'
import { debouncing } from '../../../../helper/CommonAPI'

const defaultValues = {
  issue_date: dayjs()
}

const schema = yup.object().shape({
  issue_number: yup.string().required('Issue number is required'),
  issued_by: yup.string().required('Issued By is required'),
  issue_date: yup.string().required('Blood bag issued date is required')
})

function AddAssignPlasma({ id, info, setInfo, isEdit, oldBags, maxLimit, setIsEdit, setViewMode, getRequestDetails }) {
  const [isLoading, setLoading] = useState(false)
  const [prefix, setPrefix] = useState('')

  const history = useHistory()
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    getValues,
    reset,
    formState: { errors }
  } = methods

  const handleIssue = bodydata => {
    setLoading(true)
    console.log('bodydata --> ', bodydata)

    if (prefix) {
      bodydata.issue_number = prefix + '-' + bodydata.issue_number
    }

    AxiosHelper.post('plasma-request/issue-bag', bodydata)
      .then(res => {
        console.log('res --> ', res)
        toast.success('Blood bag issued successfully')
        setLoading(false)
        getRequestDetails(`plasma-request/${id}`)
      })
      .catch(error => {
        console.log('error', error)
        toast.error(error || 'Something went wrong our side!')

        setLoading(false)
      })
  }

  const onSubmit = data => {
    data.is_issued = true
    data.request_id = id
    handleIssue(data)
  }

  const getIssueNumber = () => {
    let data = {
      request_type: 'PLASMA',
      issue_date: new Date()
    }
    AxiosHelper.post(`request/get-issue_number`, data)
      .then(res => {
        setPrefix(res?.data?.data?.issue_prefix)
        setValue('issue_number', res?.data?.data?.issue_number)
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    getIssueNumber()
  }, [])

  const getBagDetails = (e, index) => {
    if (!e.target.value) return
    let issueNumber = prefix + '-' + e.target.value
    updateAll(issueNumber, index)
  }

  const updateAll = useCallback(
    debouncing((searchValue, index) => {
      AxiosHelper.post('request/check-issue_number', { issue_number: searchValue })
        .then(res => {
          if (res?.data?.data) {
            setError(`issue_number`, {
              type: 'custom',
              message: 'This issue number is already in use'
            })
          }
        })
        .catch(error => {
          console.log('error', error)
          //  setLoading(false);
        })
    }, 500),
    []
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormLabel sx={labelStyles}>
            Issue Number
            <span className='text-danger'>*</span>
          </FormLabel>
          <RHFTextField
            onChange={e => {
              getBagDetails(e)
            }}
            InputProps={{
              startAdornment: (
                <Typography sx={{ fontSize: '12px', p: 0.5, mr: 1, borderRight: '1px solid black' }}>
                  {prefix || ''}
                </Typography>
              )
            }}
            size='small'
            fullWidth
            name='issue_number'
            sx={inputStyles}
            placeholder='Issue Number'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormLabel sx={labelStyles}>
            Issued By
            <span className='text-danger'>*</span>
          </FormLabel>
          <RHFTextField size='small' fullWidth name='issued_by' sx={inputStyles} placeholder='Person Name' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormLabel sx={labelStyles}>
            Plasma Bag Issued Date
            <span className='text-danger'>*</span>
          </FormLabel>
          <RHFDatePicker name='issue_date' disableFuture sx={inputStyles} />
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end'>
        <div className='d-flex mt-3' style={{ gap: '10px' }}>
          <button
            type='button'
            className='btn backBtn '
            onClick={() => {
              history.push('/blood-requisition')
            }}
          >
            Cancel
          </button>

          <button type='submit' disabled={isLoading} className='btn gradient-btn'>
            {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
          </button>
        </div>
      </Grid>
    </FormProvider>
  )
}

export default AddAssignPlasma
