import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import FormProvider from '../../../../../shared/FormProvider'
import { FormControl, FormLabel, Grid, MenuItem, Select } from '@mui/material'
import RHFTextField from '../../../../../shared/RHFTextfield'
import { inputStyles, labelStyles } from '../../../../../certification/EditCertificate'
import { RHFDatePicker } from '../../../../../shared/RHFDatePicker'
import RHFSelect from '../../../../../shared/RHFSelect'
import { useState } from 'react'
import { LabelDivPad } from '../../../../../blood-camp-management/addstaff/StaffForm'
import { getTestingResult, testMappings } from '../tti'

const initial = {
  hivkit: '',
  hepatitisbkit: '',
  hepatitisckit: '',
  syphiliskit: '',
  malariakit: ''
}

const schema = yup.object().shape({
  //   hivkit: yup.string(),
  //   syphiliskit: yup.string(),
  //   malariakit: yup.string(),
  //   hepatitisbkit: yup.string(),
  //   hepatitisckit: yup.string(),
  //   hiv: yup.string(),
  //   vdrl: yup.string(),
  //   malaria: yup.string(),
  //   hsbg: yup.string(),
  //   anty_hcv: yup.string()
})

export const EditTTIForm = ({ BloodTestingEntity, closeDialog, confirmDialog }) => {
  const [testValues, setTestValues] = useState(initial)
  const [show, setShow] = useState(false)

  const defaultValues = testMappings.reduce((acc, { kitKey, key }) => {
    // Set the default value for the kit type
    acc[kitKey] = BloodTestingEntity?.[kitKey] || '' // Kit selection (e.g., ELISA Kit or Rapid Kit)

    // Set the default result based on the testing result logic
    acc[key] = BloodTestingEntity?.[key] || '' // Use the current kit type value
    return acc
  }, {})


  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors }
  } = methods

  const handleTestChange = e => {
    setTestValues({
      ...testValues,
      [e.target.name]: e.target.value
    })
  }

  const onSubmit = async data => {
    await confirmDialog(data)
  }
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {testMappings.map((item, idx) => (
            <Grid item xs={12} key={idx} mb={2}>
              <FormLabel sx={labelStyles}>{item.label}</FormLabel>
              <RHFSelect sx={inputStyles} fullWidth size='small' placeholder={`${item.label}`} name={item.kitKey}>
                <MenuItem value='ELISA Kit'>ELISA Kit</MenuItem>
                <MenuItem value='Rapid Kit'>Rapid Kit</MenuItem>
              </RHFSelect>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={6}>
          {testMappings.map((item, idx) => (
            <Grid item xs={12} key={idx} mb={2}>
              <FormLabel sx={labelStyles}>{item.title}</FormLabel>
              <RHFSelect sx={inputStyles} fullWidth size='small' placeholder={`${item.key}`} name={item.key}>
                <MenuItem value='1'>{watch(item.kitKey) === 'ELISA Kit' ? 'Reactive' : 'Positive'}</MenuItem>
                <MenuItem value='0'>{watch(item.kitKey) === 'ELISA Kit' ? 'Non-Reactive' : 'Negative'}</MenuItem>
              </RHFSelect>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          marginTop: '1rem',
          justifyContent: 'flex-end'
        }}
      >
        <button type='button' className='btn backBtn' onClick={closeDialog}>
          Close
        </button>
        <button type='submit' className='btn backBtn btn-gradient-custom'>
          Save
        </button>
      </div>
    </FormProvider>
  )
}
