import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, FormLabel, Grid, MenuItem } from '@mui/material'
import RHFSelect from '../../../../../shared/RHFSelect'
import FormProvider from '../../../../../shared/FormProvider'
import { inputStyles } from '../../../../Donors'
import { labelStyles } from '../../../../../certification/EditCertificate'
import RHFTextField from '../../../../../shared/RHFTextfield'
import { no_space_regex } from '../../../../../../helper/CommonAPI'
import AxiosHelper from '../../../../../../helper/AxiosHelper'
import { RHFDateTimePicker } from '../../../../../shared/RHFDateTimePicker'
const defaultValues = {
    temp_blood_group: "",
    bag_type: "",
    bag_present: false,
}

const schema = yup.object().shape({
    assigned_date: yup.date().required('Collection Date is required'),
    bag_number: yup.string().required('Bag Number is required')
        .test(
            'length-check',
            'Spaces are not allowed',
            value => no_space_regex.test(value)
        ),
    bag_type: yup.string().required('Bag Type is required'),
    tube_number: yup.string()
    .when('bag_type', {
        is: value => value !== 'SDP',
        then: () => yup.string().required('Segment Number is required')
      }),
    bag_present: yup.boolean(),
})
const BloodBagEdit = ({ closeDialog, data, handleEditDetails }) => {
    const methods = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(schema)
    })

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors }
    } = methods


    useEffect(() => {
        if (data) {
            reset({
                assigned_date: data?.assigned_date || null,
                bag_type: data?.bag_type || '',
                bag_number: data?.bag_number || '',
                lot_number: data?.lot_number || '',
                tube_number: data?.tube_number || '',
                bleeding_duration: data?.bleeding_duration || '',
                phlebotomist: data?.phlebotomist || '',
                temp_blood_group: data?.temp_blood_group || '',
                bag_present: false,
            })
        }

    }, [data])

    const getBagDetails = (bag_num, prevNum) => {
        if (bag_num === prevNum) return

        AxiosHelper.post('donor/check-blood-bag', { bag_number: bag_num })
            .then(res => {
                if (res?.data?.data?.is_exist) {
                    setValue("bag_present", true)
                } else {
                    setValue("bag_present", false)
                }
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    const onSubmit = (bodyData) => {
        if (bodyData.bag_present) { return }
        handleEditDetails(`blood-search/blood-bag-update/${data?.id}`, bodyData)
        closeDialog(false)
    }

    useEffect(() => { getBagDetails(watch('bag_number'), data?.bag_number) }, [watch('bag_number')])
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormLabel sx={labelStyles}>
                        Collection Date<span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFDateTimePicker
                        size='small'
                        placeholder='Collection Date'
                        name='assigned_date'
                        fullWidth
                        sx={inputStyles}
                        disableFuture

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormLabel sx={labelStyles}>
                        Select Bag/Kit<span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFSelect
                        size='small'
                        placeholder='Select Bag Type'
                        name='bag_type'
                        fullWidth
                        sx={inputStyles}
                    >
                        <MenuItem value=''>--Selet Bag/Kit--</MenuItem>
                        <MenuItem value='SDP'>SDP</MenuItem>
                        <MenuItem value='SB-350 Vol-350'>SB-350 Vol-350</MenuItem>
                        <MenuItem value='SB-450 Vol-450'>SB-450 Vol-450</MenuItem>
                        <MenuItem value='DB-SAGM-350 Vol-350'>DB-SAGM-350 Vol-350 </MenuItem>
                        <MenuItem value='DB-SAGM-450 Vol-450'>DB-SAGM-450 Vol-450 </MenuItem>
                        <MenuItem value='TB-SAGM-350 Vol-350'>TB-SAGM-350 Vol-350 </MenuItem>
                        <MenuItem value='TB-SAGM-450 Vol-450 '>TB-SAGM-450 Vol-450 </MenuItem>
                    </RHFSelect>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormLabel sx={labelStyles}>
                        Bag Number <span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFTextField
                        name='bag_number'
                        sx={inputStyles}
                        placeholder={'Enter blood bag number'}
                    />
                    {watch("bag_number") && watch("bag_present") ? <FormHelperText sx={{ color: 'error.main' }}>{"Bag number already exist"}</FormHelperText> : null}
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormLabel sx={labelStyles}>
                        LOT number<span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFTextField
                        size='small'
                        placeholder='EnterLOT number'
                        name='lot_number'
                        fullWidth
                        sx={inputStyles}
                    />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormLabel sx={labelStyles}>
                        Segment Number<span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFTextField
                        size='small'
                        placeholder='Enter Segment Number'
                        name='tube_number'
                        fullWidth
                        sx={inputStyles}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormLabel sx={labelStyles}>
                        Phlebotomy Duration(in Min) <span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFTextField
                        size='small'
                        placeholder='Enter Phlebotomy Duration(in Min) '
                        name='bleeding_duration'
                        fullWidth
                        sx={inputStyles}
                    />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                    <FormLabel sx={labelStyles}>
                        Phlebotomist <span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFUserRolesAutocomplete
                        size='small'
                        placeholder='Enter Phlebotomist'
                        name='phlebotomist'
                        fullWidth
                        sx={inputStyles}
                    />
                </Grid> */}
                <Grid item xs={12} gap={'6px'} sx={{ paddingBottom: '12px', display: 'flex', justifyContent: 'flex-end' }}>

                    <button
                        type='button'
                        className='btn backBtn '
                        onClick={() => closeDialog(false)}
                    >
                        Cancel
                    </button>



                    <button
                        type='submit'
                        className='btn gradient-btn'
                    >
                        Save
                    </button>
                </Grid>
            </Grid>
        </FormProvider>

    )
}

export default BloodBagEdit
