import React from 'react'
import moment from 'moment'
import { getTenant } from '../../../middleware/auth'
import { dayCalendarSkeletonClasses } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useAppContext } from '../../../../AppContext'
import BloogGroupPrintRH from '../../../shared/BloogGroupPrintRH'

const lbl = { fontWeight: '400', fontSize: '10px', color: '#4B5563' }
const val = {
  marginTop: '8px',
  fontWeight: '400',
  fontSize: '10px',
  color: '#07101c'
}
const wraper = {
  width: '33%',
  display: 'inline-block',
  padding: '8px 0',
  borderBottom: '1px solid #E1E1E1'
}
const headings = {
  padding: '8px 10px',
  color: '#214F8A',
  fontSize: '14px',
  fontWeight: '600',
  background: '#E9EDF3'
}

const tdStyle = {
  padding: '6px 8px',
  border: 'none',
  fontSize: '12px'
}
const thStyle = {
  padding: '6px 8px',
  backgroundColor: '#E1E3E1',
  whiteSpace: 'nowrap',
  margin: '0',
  border: 'none',
  fontSize: '12px'
}

const PlasmaPrint = ({ data, info, printId, reqDetails }) => {
  // const tenant = getTenant()
  const { user: tenant } = useAppContext()
  return (
    <div
      id={printId}
      style={{
        width: '100%',
        fontSize: '10px',
        color: '#07101C',
        fontFamily: 'Inter'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '4px 16px',
          minHeight: '78px',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {tenant?.logo ? (
            <div>
              <img
                src={tenant?.logo}
                style={{ height: '50px', fontSize: '6px', paddingRight: '4px' }}
                alt='Bank Logo'
              />
            </div>
          ) : null}
          <div style={{ color: '#000', fontFamily: 'Arial', fontSize: '14px', fontWeight: '700' }}>
            {tenant?.name || 'Blood Bank Name : N/A'}
            <div style={{ fontSize: '10px', marginTop: '4px' }}>License No: {tenant?.licence_number || 'NA'}</div>
          </div>
        </div>

        <div
          style={{
            // width: "59%",
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '12px',
              marginTop: '4px',
              color: '#000',
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            <span
              style={{
                display: 'flex',
                fontSize: '14px'
              }}
            >
              Call : {tenant?.contact_number || ''}
            </span>

            <span
              style={{
                display: 'flex',
                borderLeft: '1px solid #282828',
                paddingLeft: '0.8rem'
              }}
            >
              Email : {tenant?.email || ''}
            </span>
          </div>
          <div
            style={{
              color: '#000',
              fontSize: '14px',
              fontWeight: '500'
            }}
          >
            Address :
            <span style={{ fontWeight: 500 }}>
              {' '}
              {tenant?.TenantAddressEntity?.street},{tenant?.TenantAddressEntity?.city},
              {tenant?.TenantAddressEntity?.area},{tenant?.TenantAddressEntity?.state},
              {tenant?.TenantAddressEntity?.pincode}{' '}
            </span>
          </div>
        </div>
      </div>

      <div style={{ fontSize: '14px', fontWeight: '700', color: '#000', padding: '8px', background: '#E7E7E7' }}>
        Plasma Request Details{' '}
      </div>
      <div style={{ display: 'flex', gap: '16px', padding: '0 8px', borderRadius: '8px', flexWrap: 'wrap' }}>
        {reqDetails &&
          reqDetails.map((item, idx) => {
            return (
              <div key={idx} style={{ padding: '8px', width: '200px', fontSize: '12px' }}>
                <div style={{ fontWeight: '500' }}>{item['label']}</div>
                <div style={{ marginTop: '4px', fontWeight: '600', color: '#282828' }}>{item['value'] || 'NA'}</div>
              </div>
            )
          })}
      </div>

      <div style={{ fontSize: '14px', fontWeight: '700', color: '#000', padding: '8px', background: '#E7E7E7' }}>
        Plasma Request Issued Bags List{' '}
      </div>
      <div style={{ marginTop: '16px', padding: '0' }}>
        <table
          style={{
            color: '#4B5563',
            fontWeight: '500',
            width: '100%',
            border: 'none',
            borderCollapse: 'collapse',
            textAlign: 'left'
          }}
        >
          <thead style={{ backgroundColor: '#E1E3E1' }}>
            <tr style={{ backgroundColor: '#E1E3E1' }}>
              <th style={thStyle}>SR No.</th>
              <th style={thStyle}>Bag No.</th>
              <th style={thStyle}>Date of collection</th>
              <th style={thStyle}>Blood Group</th>
              <th style={thStyle}>HBsAG</th>
              <th style={thStyle}>HIV</th>
              <th style={thStyle}>HCV</th>
              <th style={thStyle}>Syphilis</th>
              <th style={thStyle}>Mataria</th>
              <th style={thStyle}>Volume</th>
            </tr>
          </thead>
          <tbody className='inventory_tab'>
            {data?.length > 0
              ? data?.map((item, i) => (
                  <tr key={i}>
                    <td style={tdStyle}>{i + 1}.</td>
                    <td style={tdStyle}>{item?.BloodBagIssueEntity?.bag_number || '-'}</td>
                    <td style={tdStyle}>
                      {item?.collection_date ? moment(item?.collection_date).format('DD/MM/YYYY') : '-'}
                    </td>
                    <td style={tdStyle}>
                      {item?.type ? <BloogGroupPrintRH bloodGroup={item?.type} /> : '-'}
                      {/* {item?.type || '-'} */}
                    </td>
                    <td style={tdStyle}>Neg.</td>
                    <td style={tdStyle}>Neg.</td>
                    <td style={tdStyle}>Neg.</td>
                    <td style={tdStyle}>Neg.</td>
                    <td style={tdStyle}>Neg.</td>
                    <td style={tdStyle}>{item?.volume || '-'}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PlasmaPrint
