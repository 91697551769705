import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../../common/BreadCrumb'
import ScrollCard from '../../../shared/ScrollCard'
import { Card } from 'react-bootstrap'
import ScrollBar from 'react-perfect-scrollbar'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import AxiosHelper from '../../../../helper/AxiosHelper'
import dayjs from 'dayjs'
import { Add } from '@mui/icons-material'
import { LabelDiv } from '../../../screening/ScreeningDonorDetails'
import { ColumnDetail } from '../../../donor-inventory'
import Loader from '../../../shared/Loader'
import { toast } from 'react-toastify'
import AddAssignPlasma from './AddAssignPlasma'
import ViewAssignPlasma from './ViewAssignPlasma'
import { parseRequestId, printAll } from '../../../../helper/CommonAPI'
import PlasmaPrint from './PlasmaPrint'
import { isDate } from 'moment'
import ModalCustom from '../../../shared/ModalCustom'
import { labelStyles } from '../../../certification/EditCertificate'
import { inputStyles } from '../../../donors/Donors'
import { ComponentLabelsCompFn } from '../../../shared/ComponentLabelsComp'
const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard'
  },
  {
    path: '/blood-requisition',
    label: 'Fractionation Request'
  },
  {
    path: '',
    label: 'Issue Plasma Bags'
  }
]

const PlasmaAssign = () => {
  const [isLoading, setLoading] = useState(false)
  const [info, setInfo] = useState([])
  const [maxLimit, setMaxLimit] = useState(null)
  const history = useHistory()
  const [isEdit, setIsEdit] = useState(false)
  const [viewMode, setViewMode] = useState(false)
  const [currentBags, setCurrentBgs] = useState([])
  const [recentBill, setRecentBill] = useState({})
  const [reqDetails, setReqDetails] = useState([])
  const [printDetails, setPrintDetails] = useState([])
  let { id } = useParams()
  const [isIssueModal, setIsIssueModal] = useState(false)
  const [newIssueNumberErr, setNewIssueNumberErr] = useState('')
  const [newIssueNumber, setNewIssueNumber] = useState({})
  const getRequestDetails = url => {
    setLoading(true)
    AxiosHelper.get(url)
      .then(response => {
        setInfo(response?.data?.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    if (id) {
      getRequestDetails(`plasma-request/${id}`)
    }
  }, [])

  useEffect(() => {
    if (info?.component_details) {
      let limit = JSON.parse(info?.component_details)?.unit
      setMaxLimit(limit)
      if (
        info?.PlasmaRequestLogEntities &&
        info?.PlasmaRequestLogEntities?.length > 0 &&
        info?.PlasmaRequestLogEntities?.length !== limit
      ) {
        setIsEdit(true)
      }
    }
    if (info?.PlasmaRequestLogEntities) {
      const val = info?.PlasmaRequestLogEntities
      let bgs = []
      val.forEach(element => {
        if (element?.is_deleted == false) bgs.push(element?.BloodLogsEntity)
      })
      setCurrentBgs(bgs)
    }
  }, [info])

  useEffect(() => {
    if (
      // currentBags?.length == maxLimit &&
      info?.PlasmaRequestBillingEntities &&
      info?.PlasmaRequestBillingEntities?.length > 0 &&
      info?.PlasmaRequestBillingEntities[info?.PlasmaRequestBillingEntities.length - 1]?.service_type === 'FULL' &&
      info?.is_issued === true
    ) {
      setViewMode(true)
      setRecentBill(info?.PlasmaRequestBillingEntities[info?.PlasmaRequestBillingEntities.length - 1])
    }

    if (
      info?.PlasmaRequestBillingEntities &&
      info?.PlasmaRequestBillingEntities[info?.PlasmaRequestBillingEntities.length - 1]?.service_type === 'FULL'
    ) {
      setRecentBill(info?.PlasmaRequestBillingEntities[info?.PlasmaRequestBillingEntities.length - 1])
    }
  }, [currentBags, maxLimit])

  const component_details = info?.component_details ? JSON.parse(info?.component_details) : ''

  // console.log('info --> ', component_details)

  const handleBasicDetails = () => {
    let issueInfo = []
    if (info?.is_issued === true) {
      issueInfo = [
        { label: 'Issue Number', value: info?.issue_number || '' },
        { label: 'Issued By', value: info?.issued_by || '' },
        { label: 'Issue Date', value: info?.issue_date ? dayjs(info?.issue_date).format('DD-MMM-YYYY') : '-' }
      ]
    }
    const details = [
      { label: 'Request Id', value: info?.request_id || '' },
      { label: 'Request Date', value: info?.request_date ? dayjs(info?.request_date).format('DD-MMM-YYYY') : '-' },
      { label: 'Company Name', value: info?.BuyerEntity?.name || '' },
      { label: 'License No.', value: info?.BuyerEntity?.licence_number || '' },
      {
        label: 'Requested Component',
        value: component_details !== '' ? ComponentLabelsCompFn(component_details?.component) : ''
      },
      //  { label: "Requested Units", value: info?.component_details ? JSON.parse(info.component_details)?.unit : "" },
      { label: 'Requested Units', value: component_details !== '' ? component_details?.unit : '' },
      { label: 'Quantity(Litre)', value: recentBill?.ffp_quantity || '' },
      { label: 'Payment', value: recentBill?.service_type === 'FULL' ? 'Done' : '' },
      { label: 'Total Amount', value: recentBill?.total_amount ? <>&#x20B9;{recentBill?.total_amount}</> : '' },
      ...issueInfo
    ]
    const print_details = [
      { label: 'Request Id', value: info?.request_id || '' },
      { label: 'Company Name', value: info?.BuyerEntity?.name || '' },
      { label: 'License No.', value: info?.BuyerEntity?.licence_number || '' },
      { label: 'Request Date', value: info?.request_date ? dayjs(info?.request_date).format('DD-MMM-YYYY') : '-' },
      {
        label: 'Requested Component',
        value: component_details !== '' ? ComponentLabelsCompFn(component_details?.component) : ''
      },
      { label: 'Requested Units', value: component_details !== '' ? component_details?.unit : '' },
      { label: 'Quantity(Litre)', value: recentBill?.ffp_quantity || '' },
      { label: 'Issue Number', value: info?.issue_number || '' },
      { label: 'Issue Date', value: info?.issue_date ? dayjs(info?.issue_date).format('DD-MMM-YYYY') : '-' }
    ]
    setPrintDetails(print_details)
    setReqDetails(details)
  }

  useEffect(() => {
    handleBasicDetails()
  }, [info, recentBill])

  if (isLoading) {
    return <Loader isLoading={true} />
  }
  const handleEditIssue = () => {
    setIsIssueModal(true)
    const issData = parseRequestId(info?.issue_number)
    setNewIssueNumber({ issuePrefix: issData?.prefix, issueNumber: issData?.request_id, id: id })
  }
  const handleIssueNumberCheck = iNumber => {
    AxiosHelper.post('request/check-issue_number', { issue_number: iNumber })
      .then(res => {
        if (res?.data?.data) {
          setNewIssueNumberErr('This issue number is already in use')
        } else {
          setNewIssueNumberErr('')
        }
      })
      .catch(error => {
        console.log('error', error)
        //  setLoading(false);
      })
  }
  const handleEditRequest = (log_id, bagnumber) => {
    setLoading(true)
    if (!newIssueNumberErr && !bagnumber) {
      return
    }
    AxiosHelper.put(`plasma-request/issue-number/${id}/edit`, { issue_number: bagnumber })
      .then(res => {
        getRequestDetails(`plasma-request/${id}`)
        setLoading(false)
        setIsIssueModal(false)
        toast.success('Issue number has been successfully updated.')
      })
      .catch(error => {
        console.log('error', error)
        setLoading(false)
        toast.error(error || 'An error occurred. Please try again later.')
      })
  }
  return (
    <>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />
      <ModalCustom
        open={isIssueModal}
        closeDialog={() => setIsIssueModal(false)}
        scroll='paper'
        title='Update Issue Number'
        dialogStyle={{
          pt: 0,
          px: 2
        }}
      >
        <div>
          <FormLabel sx={labelStyles}>
            Issue Number <span className='text-danger'>*</span>
          </FormLabel>
          <div>
            <TextField
              InputProps={{
                startAdornment: (
                  <Typography sx={{ fontSize: '14px', p: 0.5, mr: 1, borderRight: '1px solid black' }}>
                    {newIssueNumber?.issuePrefix || ''}
                  </Typography>
                )
              }}
              size='small'
              onChange={e => {
                setNewIssueNumber({ ...newIssueNumber, issueNumber: e.target.value })
                if (e.target.value) {
                  handleIssueNumberCheck(
                    `${newIssueNumber?.issuePrefix || ''}${newIssueNumber?.issuePrefix ? '-' : ''}${e.target.value}`
                  )
                }
              }}
              fullWidth
              value={newIssueNumber?.issueNumber}
              sx={{ ...inputStyles, maxWidth: 350 }}
              placeholder='Issue Number'
            />
            <FormHelperText
              sx={{
                color: 'error.main',
                textTransform: 'none',
                height: '25px'
              }}
            >
              {newIssueNumberErr
                ? newIssueNumberErr
                : newIssueNumber?.issueNumber?.length === 0
                  ? 'Enter Issue Number'
                  : ''}
            </FormHelperText>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '16px' }}>
          <button
            type='button'
            disabled={isLoading}
            className='btn gradient-btn'
            onClick={() =>
              handleEditRequest(
                id,
                `${newIssueNumber?.issuePrefix || ''}${newIssueNumber?.issuePrefix ? '-' : ''}${newIssueNumber?.issueNumber}`
              )
            }
          >
            {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Update'}
          </button>
          <button type='button' className='btn backBtn' onClick={() => setIsIssueModal(false)}>
            Cancel
          </button>
        </div>
      </ModalCustom>
      <ScrollCard>
        {viewMode ? (
          <Grid container justifyContent={'flex-end'}>
            <div className='d-flex' style={{ gap: '10px' }}>
              {/* <button
                  type="button"
                  className="btn backBtn "
                  onClick={() => {
                    history.push("/blood-requisition");
                  }}
                >
                  Cancel
                </button> */}

              {/* <a target="_blank" href={`/print-pages/issue-blood-bag-details/${id}`}> */}
              <button
                type='button'
                disabled={isLoading}
                className='btn gradient-btn'
                onClick={() => printAll('issue_blood_bag_plasma', 'iframe_id')}
              >
                {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Print'}
              </button>
              {/* </a> */}
            </div>
          </Grid>
        ) : null}
        <LabelDiv title={info?.is_issued === true ? 'Plasma Bag Issue Details' : 'Issue Plasma Bags'}>
          <Grid container spacing={2}>
            {reqDetails.map((item, idx) => {
              if (item?.label === 'Issue Number') {
                return (
                  <div className='col align-items-center '>
                    <label>{item['label']}</label>
                    <h6>
                      <span style={{ position: 'relative', top: '3px' }}>{item['value'] || 'NA'} </span>
                      <img
                        src={'/assets/images/editIcon.svg'}
                        onClick={() => handleEditIssue()}
                        style={{ height: '24px', borderRadius: '0', paddingLeft: '8px', cursor: 'pointer' }}
                        alt='edit'
                      />
                    </h6>
                  </div>
                )
              } else {
                return (
                  <Grid key={idx} item xs={12} sm={6} md={4} lg={3}>
                    <ColumnDetail label={item['label']} title={item['value'] || 'NA'} />
                  </Grid>
                )
              }
            })}
          </Grid>
        </LabelDiv>

        <div className='d-none'>
          {viewMode ? (
            <>
              <h4> Issued Bags Details</h4>
              <PlasmaPrint
                data={currentBags}
                info={info}
                setIsEdit={setIsEdit}
                setViewMode={setViewMode}
                reqDetails={printDetails}
                printId='issue_blood_bag_plasma'
              />
            </>
          ) : null}
        </div>

        {viewMode ? (
          <>
            <h4> Issued Bags Details</h4>
            <ViewAssignPlasma data={currentBags} info={info} setIsEdit={setIsEdit} setViewMode={setViewMode} />
          </>
        ) : (
          <AddAssignPlasma
            id={id}
            info={info}
            setInfo={setInfo}
            isEdit={isEdit}
            oldBags={currentBags}
            maxLimit={maxLimit}
            setIsEdit={setIsEdit}
            setViewMode={setViewMode}
            getRequestDetails={getRequestDetails}
          />
        )}
      </ScrollCard>
    </>
  )
}

export default PlasmaAssign
