import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import ScrollBar from 'react-perfect-scrollbar'
import { Delete } from '@mui/icons-material'
import {
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { toast } from 'react-toastify'
import AxiosHelper from '../../../../helper/AxiosHelper'
import ConfirmBox from '../../../shared/ConfirmBox'
import { searchStyles } from '../../../shared/SearchBox'
import PaginationLink from '../../../pagination/PaginationLink'
import { typeAbbreviations } from '../../../dashboard/components/AvailableBlood'
import NoDataFound from '../../../common/NoDataFound'
import Loader from '../../../shared/Loader'
import { inputStyles } from '../../../certification/EditCertificate'
import { bloodGroupApiToUser, checkTimeGap, debouncing } from '../../../../helper/CommonAPI'
import LoadingButton from '../../../shared/LoadingButton'
import { getObj } from '../../AddInventory'
import { ProfileBagRedirect } from '../../../donors/profile/utils/shared/profile-bag-redirect'
import BloogGroupPrintRH from '../../../shared/BloogGroupPrintRH'
import { ComponentLabelsCompFn } from '../../../shared/ComponentLabelsComp'
import { dangerTextStyles } from '../../../shared/styles/SharedStyles'

export const isVolumeExceeding = (item, volume) => {
  const { volume_range, standard_volume } = item

  // Parse lower and upper limits from volume_range or use standard_volume if volume_range is null
  const [lowerLimit, upperLimit] = volume_range
    ? volume_range.split('-').map(v => parseInt(v, 10))
    : [null, parseInt(standard_volume, 10)]

  // Parse the current volume
  const currentVolume = volume ? parseInt(volume, 10) : null

  // Check if the volume exceeds the valid range or limit
  if (volume_range) {
    return !(currentVolume !== null && currentVolume >= lowerLimit && currentVolume <= upperLimit)
  }
  return !(currentVolume !== null && currentVolume <= upperLimit)
}

const ValidateBags = ({ setCurrentTab, setProgressCount, bag_number = '', redirectDonor }) => {
  const [openEdit, setOpenEdit] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [volumeList, setVolumeList] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(null)
  const [page, setPage] = useState(1)
  const [selectedBagIds, setSelectedBagIds] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [storedCombData, setStoredCombData] = useState([])
  const [search, setSearch] = useState('')

  const updateBloodLog = (id, volume) => {
    // Convert volume to a number for comparison
    const numericVolume = Number(volume)

    // Create a copy of the current state to avoid mutating directly
    const updatedBloodLogData = [...storedCombData]

    // Remove record if volume is empty or 0
    if (!numericVolume || numericVolume <= 0) {
      const filteredData = updatedBloodLogData.filter(log => log.blood_log_id !== id)
      setStoredCombData(filteredData) // Update state with the filtered data
      return
    }

    // Check if the record already exists
    const existingRecordIndex = updatedBloodLogData.findIndex(log => log.blood_log_id === id)

    if (existingRecordIndex !== -1) {
      // Update the volume if record exists
      updatedBloodLogData[existingRecordIndex].volume = `${numericVolume}`
    } else {
      // Add a new record if it doesn't exist
      updatedBloodLogData.push({ blood_log_id: id, volume: `${numericVolume}` })
    }
    // Update the state with the new data
    setStoredCombData(updatedBloodLogData)
  }

  const handleCheckboxChange = bagId => {
    if (selectedBagIds.includes(bagId)) {
      // Remove the bagId if already selected
      setSelectedBagIds(selectedBagIds?.filter(id => id !== bagId))
    } else {
      // Add the bagId to the selected list
      setSelectedBagIds([...selectedBagIds, bagId])
    }
  }

  // Toggle "Select All" checkbox
  const handleSelectAllChange = () => {
    if (selectAll) {
      // Unselect all rows
      setSelectedBagIds([])
    } else {
      // Select all rows
      const allBagIds = volumeList?.map(row => row?.id)
      setSelectedBagIds(allBagIds)
    }
    setSelectAll(!selectAll)
  }

  const getAllLogs = inputValue => {
    setPageLoading(true)
    AxiosHelper.get(`blood-process/validate?search=${inputValue || ''}`)
      .then(res => {
        let dataa = res?.data?.data
        setVolumeList(dataa?.response)
        setPagination(dataa?.pagination)
        setPageLoading(false)
        let pending = {
          completedCount: dataa?.completedCount,
          pendingBagCount: dataa?.pendingBagCount,
          pendingValidationCount: dataa?.pendingValidationCount,
          pendingVolumeCount: dataa?.pendingVolumeCount
        }
        setProgressCount(pending)
      })
      .catch(error => {
        console.log('error', error)
        setPageLoading(false)
      })
  }

  useEffect(() => {
    getAllLogs(bag_number ? bag_number : '')
  }, [page, bag_number])

  function updateOnSave() {
    if (selectedBagIds?.length) {
      const requestBody = selectedBagIds?.map(id => ({ blood_log_id: id }))

      setLoading(true)
      let url = `blood-process/validate`
      AxiosHelper.post(url, requestBody)
        .then(() => {
          toast.success('Validation done successfully')
          setCurrentTab(4)
          setLoading(false)
          if (bag_number) {
            redirectDonor()
          }
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    } else {
      toast.error('Please select bags to continue')

      return
    }
  }

  const updateAll = useCallback(
    debouncing(searchValue => {
      getAllLogs(searchValue)
    }, 500),
    []
  )

  function saveVolume() {
    if (storedCombData?.length) {
      let bodyData = storedCombData

      setLoading(true)
      let url = `blood-process/update-volume`
      AxiosHelper.post(url, bodyData)
        .then(() => {
          toast.success('Volume updated successfully')
          setLoading(false)
          setOpenEdit(false)
          getAllLogs()
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
        })
    } else {
      toast.error('Please enter volume in one Component to continue')

      return
    }
  }

  return (
    <>
      <Loader isLoading={pageLoading} />

      <div className='search-and-filter-container justify-between align-items-center mt-2'>
        {bag_number ? (
          <div></div>
        ) : (
          <div className='search-container add-dnr-search'>
            <input
              className='search-input'
              type='text'
              placeholder='Search By Blood Bag Number'
              value={search}
              onChange={e => {
                setSearch(e.target.value)
                updateAll(e.target.value)
              }}
            />
            <img src='/assets/images/search-icon.svg' alt='search-icon' />
          </div>
        )}
        {openEdit ? (
          <div className='d-flex gap-2'>
            <button
              onClick={() => {
                setOpenEdit(false)
              }}
              className='btn backBtn'
            >
              Cancel
            </button>
            <LoadingButton
              isLoading={loading}
              disabled={storedCombData?.length === 0}
              onClick={() => {
                saveVolume()
              }}
              title='Save'
            />
          </div>
        ) : (
          <div className='d-flex gap-2'>
            <button
              onClick={() => {
                setOpenEdit(true)
              }}
              className='btn backBtn'
            >
              Edit Volume
            </button>
            <LoadingButton
              isLoading={loading}
              disabled={selectedBagIds?.length === 0}
              onClick={() => {
                updateOnSave()
              }}
              title='Validate Bags'
            />
          </div>
        )}
      </div>
      <Grid item xs={12}>
        <Card sx={{ mt: 2 }}>
          <div className='table-responsive mt-2'>
            <ScrollBar>
              <TableContainer className='table table-hover table-element-center'>
                <Table aria-label='collapsible table'>
                  <TableHead>
                    <TableRow>
                      {openEdit ? null : (
                        <TableCell style={{ maxWidth: 50 }}>
                          <Checkbox
                            sx={{ p: 0 }}
                            checked={selectAll || selectedBagIds?.length === volumeList?.length}
                            onChange={handleSelectAllChange}
                          />
                        </TableCell>
                      )}
                      <TableCell>Blood Bag Info</TableCell>
                      <TableCell sx={{ maxWidth: '90px' }}>Component</TableCell>
                      <TableCell>Tube No./Blood Group</TableCell>
                      <TableCell sx={{ maxWidth: '90px' }}>Collection Date</TableCell>
                      <TableCell sx={{ maxWidth: '90px' }}>Volume Range</TableCell>
                      <TableCell sx={{ maxWidth: '90px' }}>Volume</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {volumeList &&
                      volumeList.map((row, i) => {
                        const bagRnge = getObj(row?.component)
                        return (
                          <TableRow key={i}>
                            {openEdit ? null : (
                              <TableCell
                                style={{ maxWidth: 50 }}
                                sx={{
                                  '&.MuiTableCell-root': { verticalAlign: 'middle !important', fontWeight: 'bold' }
                                }}
                              >
                                <Checkbox
                                  checked={selectedBagIds?.includes(row.id)}
                                  onChange={() => handleCheckboxChange(row?.id)}
                                  sx={{ p: 0 }}
                                />
                              </TableCell>
                            )}
                            <TableCell
                              sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', fontWeight: 'bold' } }}
                            >
                              <ProfileBagRedirect bag_id={row?.BloodBagIssueEntity?.id}>
                                {row?.BloodBagIssueEntity?.bag_number || '-'}
                                <div className='primary-request-text'>{row?.BloodBagIssueEntity?.bag_type || '-'}</div>
                              </ProfileBagRedirect>
                            </TableCell>
                            <TableCell
                              sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', fontWeight: 'bold' } }}
                            >
                              {row?.component ? ComponentLabelsCompFn(row?.component) : '-'}
                            </TableCell>
                            <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                              {row?.BloodBagIssueEntity?.tube_number || '-'}
                              <br />
                              {row?.BloodBagIssueEntity?.temp_blood_group
                                ? 
                                <BloogGroupPrintRH bloodGroup={row?.BloodBagIssueEntity?.temp_blood_group} />
                                // bloodGroupApiToUser[row?.BloodBagIssueEntity?.temp_blood_group]
                                : '-'}
                            </TableCell>
                            <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                              <div style={checkTimeGap(row?.collection_date,dayjs(),6) ? dangerTextStyles : {}}> {row?.collection_date ? dayjs(row?.collection_date).format('DD-MM-YYYY HH:mm') : '-'}</div>      
                            </TableCell>

                            <TableCell
                              sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', maxWidth: 150 } }}
                            >
                              {bagRnge?.volume_range || bagRnge?.standard_volume || ''}
                            </TableCell>
                            {openEdit ? (
                              <TableCell
                                className={`${isVolumeExceeding(bagRnge, storedCombData.find(item => item?.blood_log_id == row?.id)?.volume || row?.volume) ? 'danger-blood-stock' : ''}`}
                                sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', minWidth: 150 } }}
                              >
                                <TextField
                                  value={storedCombData.find(item => item?.blood_log_id == row?.id)?.volume}
                                  defaultValue={row?.volume}
                                  onChange={e => {
                                    updateBloodLog(row?.id, e.target.value)
                                  }}
                                  sx={inputStyles}
                                  placeholder='00'
                                />
                              </TableCell>
                            ) : (
                              <TableCell
                                sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', maxWidth: 150 } }}
                                className={`${isVolumeExceeding(bagRnge, row?.volume) ? 'danger-blood-stock' : ''}`}
                              >
                                {row?.volume || ''}
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </ScrollBar>
          </div>
          {volumeList?.length == 0 && (
            <div className='dnr-no-data-found'>
              <NoDataFound title='No Record found' />
            </div>
          )}
          <div className='d-flex justify-content-end mt-2'>
            <PaginationLink pagination={pagination} getNext={page => setPage(page)} />
          </div>
        </Card>
      </Grid>
    </>
  )
}

export default ValidateBags
