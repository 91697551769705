import moment from 'moment'
import { CardHeader } from '../shared/card-header'
import { toast } from 'react-toastify'
import AxiosHelper from '../../../../../helper/AxiosHelper'
import ModalCustom2 from '../../../../shared/ModalCustom2'
import { useEffect, useState } from 'react'
import { EditComponentForm } from './component/edit'
import ModalCustom from '../../../../shared/ModalCustom'
import ToolTips from '../../../../common/ToolTips'
import { StatusCapsule } from '../shared/status-capsule'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { checkExpiredAndDiscaredReason } from '../../../../../helper/CommonAPI'
import { ComponentLabelsCompFn } from '../../../../shared/ComponentLabelsComp'
import PlasmaAssign from '../../../../blood-requisition/plasma/assign-plasma'

export const editBloodLogAPI = async (b_id, bodyData) => {
  const res = await AxiosHelper.put(`blood-search/${b_id}/component-edit`, bodyData)
  const { data } = await res
  return data
}

export const CTABtns = ({
  actionCallBack,
  editBtn = true,
  deleteBtn = true,
  disableEdit,
  editToolTip,
  deleteToolTip
}) => {
  return (
    <div className='pd-ib-item'>
      {/* <img
            src={'/assets/images/donor_profile/split.png'}
            alt='split'
            className='split'
            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
          />
          <img
            src={'/assets/images/donor_profile/editbtn.svg'}
            alt='edit'
            className='edit'
            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
          /> */}

      {editBtn ? (
        <ToolTips title={editToolTip}>
          {disableEdit ? (
            <img
              src={'/assets/images/donor_profile/editbtn.svg'}
              alt='edit'
              className='edit'
              style={{
                cursor: 'not-allowed',
                width: '24px',
                height: '24px',
                filter: 'grayscale(100%) brightness(0.7)',
                opacity: 0.6
              }}
              name='edit'
            />
          ) : (
            <img
              src={'/assets/images/donor_profile/editbtn.svg'}
              alt='edit'
              className='edit'
              style={{ cursor: 'pointer', width: '24px', height: '24px' }}
              name='edit'
              onClick={actionCallBack}
            />
          )}
        </ToolTips>
      ) : null}

      {deleteBtn ? (
        <ToolTips title={deleteToolTip}>
          <img
            src={'/assets/images/donor_profile/deletebtn.png'}
            alt='delete'
            className='delete'
            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
            name='delete'
            onClick={actionCallBack}
          />
        </ToolTips>
      ) : null}
    </div>
  )
}

export const STATUS = {
  ALLOTED: 'Issued - Alloted',
  PENDING: 'Issued - Pending',
  CANCEL: 'Cancel',
  EXPIRED: 'Discarded'
}

export const AllottedComponent = ({
  item,
  setAllotedComp,
  allotedComp,
  setLoading,
  getAllotedComponents,
  trailData
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [disableEdit, setDisableEdit] = useState(false)
  const [toolTipMsg, setToolTipMsg] = useState('')
  const history = useHistory()
  const statusText =
    item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.is_deleted === true ||
      item?.BloodRequestLogsEntity?.is_deleted === true
      ? '-'
      : item?.PlasmaRequestLogEntity
        ? item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.issue_number
          ? STATUS.ALLOTED
          : STATUS.PENDING
        : item?.BloodRequestLogsEntity
          ? item?.BloodRequestLogsEntity?.issue_number
            ? STATUS.ALLOTED
            : STATUS.PENDING
          : item?.BloodLoanLogsEntity
            ? item?.BloodLoanLogsEntity?.issue_number
              ? STATUS.ALLOTED
              : STATUS.PENDING
            : checkExpiredAndDiscaredReason(item,allotedComp?.BloodTestingEntity)
              ? STATUS.EXPIRED
              : STATUS.PENDING

  // Determine the status for the StatusCapsule based on the status text
  const status = statusText === STATUS.ALLOTED ? 'done' : checkExpiredAndDiscaredReason(item,allotedComp?.BloodTestingEntity) ? 'cancel' : 'pending'
  const deleteBloodLog = async id => {
    try {
      const res = await AxiosHelper.delete(`blood-search/${id}/component`)
      if (res.data.data.status === false) throw new Error(res?.data?.message || 'Something went wrong!')
      return res.data
    } catch (error) {
      console.log(error)
      toast.error(error || 'Something went wrong!')
    }
  }

  const confirmModal = async () => {
    setConfirm(true)
    const res = await deleteBloodLog(item.id)
    if (!res) {
      return
    }
    const { data, statusCode } = res
    if (statusCode === 200) {
      // Filter out the deleted BloodLogEntity by id
      const updatedBloodLogsEntities = allotedComp.BloodLogsEntities.filter(bloodLog => bloodLog.id !== item.id)

      // Update the state with the new array
      setAllotedComp(prevState => ({
        ...prevState,
        BloodLogsEntities: updatedBloodLogsEntities
      }))
      toast.success('Blood Component Deleted Successfully')
    } else {
      toast.error('Failed to delete Blood Component')
    }
    setOpenModal(false)
  }

  const cancelModel = () => {
    setOpenModal(false)
  }
  const actionCallBack = async e => {
    setLoading(true)
    if (e.target.name === 'delete') {
      setOpenModal(true)
    }
    if (e.target.name === 'edit') {
      setOpenEditModal(true)
    }
    setLoading(false)
  }

  const saveEditModal = async (id, formData) => {
    try {
      const res = await editBloodLogAPI(id, formData)
      const { data } = await res
      if (!data) {
        throw new Error('No Response from server!')
      }
      getAllotedComponents()

      setOpenEditModal(false)
    } catch (error) {
      console.log(error)
      toast.error(error?.message || 'Something went wrong!')
    }
  }

  const cancelEditModel = async () => {
    setOpenEditModal(false)
  }

  const entityHelper = (element, parentEntity = false) => {
    // Type 1: Request
    // Type 2: PlasmaAssign
    // Type 3: BloodLoan

    if (!parentEntity) {
      if (element?.BloodRequestLogsEntity) return { info: element?.BloodRequestLogsEntity?.BloodRequestEntity, reqType: 1 }
      else if (element?.PlasmaRequestLogEntity) return { info: element?.PlasmaRequestLogEntity?.PlasmaRequestEntity, reqType: 2 }
      else if (element?.BloodLoanLogsEntity) return { info: element?.BloodLoanLogsEntity?.BloodLoanRequestEntity, reqType: 3 }
    } else {
      if (element?.BloodRequestLogsEntity) return { info: element?.BloodRequestLogsEntity, reqType: 1 }
      else if (element?.PlasmaRequestLogEntity) return { info: element?.PlasmaRequestLogEntity, reqType: 2 }
      else if (element?.BloodLoanLogsEntity) return { info: element?.BloodLoanLogsEntity, reqType: 3 }
    }
  }

  const checkDisable = () => {
    {
      const boolVal =
        item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.is_deleted === true ||
          item?.BloodRequestLogsEntity?.is_deleted === true
          ? false
          : item?.PlasmaRequestLogEntity
            ? item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.issue_number
              ? true
              : false
            : item?.BloodRequestLogsEntity
              ? item?.BloodRequestLogsEntity?.issue_number
                ? true
                : false
              : item?.BloodLoanLogsEntity
                ? item?.BloodLoanLogsEntity?.issue_number
                  ? true
                  : false
                : false

      setDisableEdit(trailData?.is_defered || boolVal)
      if (trailData?.is_defered) {
        setToolTipMsg('User is Defered')
      }

      if (boolVal) {
        setToolTipMsg('Component is already Issued or Transfered')
      }

      if (!trailData?.is_defered && !boolVal) {
        setToolTipMsg('Edit')
      }
    }
  }

  useEffect(() => {
    checkDisable()
  }, [])


  return (
    <div className=' shadowCell p-2 mb-3 pb-3'>
      <ModalCustom2
        confirmDialog={confirmModal}
        closeDialog={cancelModel}
        title={'Delete Blood Bag Component'}
        open={openModal}
      >
        <div>
          <p>
            Do you confirm that you want to <b>delete</b> blood bag component?
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CardHeader
              expiredDate={item?.expiry_date}
              collectionDate={item?.collection_date}
              r_id={entityHelper(item)?.info?.request_id}
            >
              {item?.component ? ComponentLabelsCompFn(item?.component) : '-'}
              {/* <span className='trail'>(std. vol.: {item?.standard_volume} ml)</span> */}
            </CardHeader>
          </div>
          <div className='donation-history-status-header mt-2 ' style={{ fontSize: '14px' }}>
            Total Collection: <span style={{ color: '#214F8A' }}>{item?.volume} ml</span>
          </div>
        </div>
      </ModalCustom2>
      <ModalCustom
        // confirmDialog={saveEditModal}
        closeDialog={cancelEditModel}
        title={'Edit Blood Bag Component'}
        open={openEditModal}
      >
        <EditComponentForm confirmDialog={saveEditModal} closeDialog={cancelEditModel} item={item} />
      </ModalCustom>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <CardHeader
          expiredDate={item?.expiry_date}
          collectionDate={item?.collection_date}
          actionBtns
          actionCallBack={actionCallBack}
          disableEdit={disableEdit}
          editToolTip={toolTipMsg}
        >
          {item?.component}{item?.is_aliqout ? ' (Aliquot)' : ''}
          <span className='trail'>(std. vol.: {item?.standard_volume} ml)</span>
        </CardHeader>
      </div>
      <div className='donation-history-status-header mt-2 ' style={{ fontSize: '14px' }}>
        Total Collection: <span style={{ color: '#214F8A' }}>{item?.volume} ml</span>
      </div>
      <div
        onClick={() => {
          if (entityHelper(item)?.reqType === 1 && entityHelper(item)?.info?.id) { history.push(`/blood-requisition/view/${entityHelper(item)?.info?.id}?tab=5`) }
          if (entityHelper(item)?.reqType === 2 && entityHelper(item)?.info?.id) { history.push(`/blood-requisition/plasma/${entityHelper(item)?.info?.id}`) }
          if (entityHelper(item)?.reqType === 3 && entityHelper(item)?.info?.id) { history.push(`/blood-loan/transfer/request/${entityHelper(item)?.info?.id}`) }
        }
        }
        className='mt-2 pb-2'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '10px',
          borderBottom: '1px solid #ddd',
          fontSize: '14px',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <div>
          <StatusCapsule status={status}>{statusText}</StatusCapsule>
        </div>
        {statusText === 'Discarded' ? <div>{checkExpiredAndDiscaredReason(item,allotedComp?.BloodTestingEntity) || '-'}</div> : null}  
        <div className={entityHelper(item)?.info?.request_id ? 'linkableCss' : ''}>{entityHelper(item)?.info?.request_id || '-'}</div>
        <div>
          {/* {entityHelper(item)?.hospital_name
            ? `${item?.PlasmaRequestLogEntity ? 'Plasma' : item?.BloodRequestLogsEntity ? 'Blood' : item?.BloodLoanLogsEntity ? 'Blood Loan' : null} Request (${entityHelper(item)?.hospital_name})`
            : '-'} */}
          {item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.is_deleted === true ||
            item?.BloodRequestLogsEntity?.is_deleted === true
            ? '-'
            : item?.PlasmaRequestLogEntity
              ? `Plasma Request (${item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.BuyerEntity?.name})` || '-'
              : item?.BloodRequestLogsEntity?.BloodRequestEntity?.hospital_name
                ? `Blood Request (${item?.BloodRequestLogsEntity?.BloodRequestEntity?.hospital_name})`
                : item?.BloodLoanLogsEntity?.BloodLoanRequestEntity
                  ? `Bulk Transfer (${item?.BloodLoanLogsEntity?.BloodLoanRequestEntity?.RequestTenant?.name})`
                  : '-'}
        </div>
        <div>
          {/* {entityHelper(item, true)?.issue_date
            ? 'Issued On: ' + moment(entityHelper(item, true)?.issue_date).format('DD MMM YYYY')
            : '-'} */}

          {item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.is_deleted === true ||
            item?.BloodRequestLogsEntity?.is_deleted === true
            ? '-'
            : item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.issue_date
              ? `Issued On: ${moment(item?.PlasmaRequestLogEntity?.PlasmaRequestEntity?.issue_date).format('DD-MM-YYYY')}`
              : item?.BloodRequestLogsEntity?.issue_date
                ? `${moment(item?.BloodRequestLogsEntity?.issue_date).format('DD-MM-YYYY')}`
                : item?.BloodLoanLogsEntity?.BloodLoanRequestEntity?.request_date
                  ? `${moment(item?.BloodLoanLogsEntity?.BloodLoanRequestEntity?.request_date).format('DD-MM-YYYY')}`
                  : '-'}
        </div>
      </div>
    </div>
  )
}
