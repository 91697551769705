import {
  Autocomplete,
  Card,
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import React, { useCallback, useEffect, useState } from 'react'
import RHFTextField from '../../../shared/RHFTextfield'
import LoadingButton from '../../../shared/LoadingButton'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import FormProvider from '../../../shared/FormProvider'
import { useForm } from 'react-hook-form'
import { inputStyles, labelStyles } from '../../../certification/EditCertificate'
import PaginationLink from '../../../pagination/PaginationLink'
import ScrollBar from 'react-perfect-scrollbar'
import { bloodGroupArray } from '../../../donors/profile/EditProfile'
import AxiosHelper from '../../../../helper/AxiosHelper'
import { bagTypeItems, bloodGroupApiToUser, checkTenantId, checkTimeGap, debouncing, getComponentname } from '../../../../helper/CommonAPI'
import dayjs from 'dayjs'
import NoDataFound from '../../../common/NoDataFound'
import { RHFDateTimePicker } from '../../../shared/RHFDateTimePicker'
import RHFSelect from '../../../shared/RHFSelect'
import Loader from '../../../shared/Loader'
import { toast } from 'react-toastify'
import { bloodComponents } from '../../../donor-inventory/AddInventory'
import { typeAbbreviations } from '../../../dashboard/components/AvailableBlood'
import { getName } from '../../../middleware/auth'
import BloogGroupPrintRH from '../../../shared/BloogGroupPrintRH'
import { ProfileBagRedirect } from '../../../donors/profile/utils/shared/profile-bag-redirect'
import AddMachine from '../../../admin-settings/Machines/AddMachine'
import { DatePicker } from '@mui/x-date-pickers'
import { dangerTextStyles } from '../../../shared/styles/SharedStyles'

const defaultValues = {
  prepared_by: '',
  prepared_machine: '',
  combination: '',
  prepration_date: new Date()
}

const menuItems = ['WB', 'SDP', 'PRBC,FFP', 'PRBC,RDP', 'PRBC,FFP,RDP', 'PRBC,CPP,Cryo', 'PRBC,CPP,RDP,Cryo']

const bagTypeItemsFix = [
  { label: 'SDP', value: 'SDP' },
  { label: 'Single', value: 'SB-350 Vol-350~SB-450 Vol-450' },
  { label: 'Double', value: 'DB-CPDA-350 Vol-350~DB-CPDA-450 Vol-450' },
  { label: 'Triple', value: 'TB-CPDA-350 Vol-350~TB-CPDA-450 Vol-450' }
]

const schema = yup.object().shape({
  prepared_machine: yup.string().required('Please select Machine name'),
  combination: yup.string().required('Please select Combination'),
  prepared_by: yup.string().required('Please enter Person name'),
  prepration_date: yup.string().required('Please select Date & Time')
})

const AddSegment = ({ setCurrentTab, setProgressCount, bag_number = '' }) => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isLoading, setPageLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [allItems, setAllItems] = useState([])
  const [pageData, setPageData] = useState(null)
  const [storedCombData, setStoredCombData] = useState([])
  const [selectedBags, setSelectedBags] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [userData, setUserData] = useState([])
  const [allMachines, setAllMachines] = useState([])
  const [selectedMachine, setSelectedMachines] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const [campList, setCampList] = useState([])
  const [searchDateFrom, setSearchDateFrom] = useState('')
  const [searchDateTo, setSearchDateTo] = useState('')
  const [bagType, setBagType] = useState(null)
  const [camp, setCamp] = useState('')
  const [searchSite, setSearchSite] = useState('')
  const [isFiltered, setIsFiltered] = useState(false)
  const [currSearchPrams, setCurrSearchPrams] = useState(null)
  const [closefltr, setClosefltr] = useState(false)

  const openMenu = event => {
    setAnchor(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchor(null)
  }

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = methods

  const getUsers = () => {
    setValue('prepared_by', getName())
    AxiosHelper.get('users')
      .then(response => {
        let userss = response.data.data

        let newAraay = userss?.map(item => {
          return item?.name
        })
        setUserData(newAraay)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const getCamps = () => {
    let url = 'donor/camp-list'
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        setCampList(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getMachines = () => {
    let url = `setting/tenant-machine?category=blood-process`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        let res = response.data.data
        setAllMachines(res?.response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getPendingInventoryList = (inputValue, filterss) => {
    setPageLoading(true)
    let url = `blood-stock/bulk-bags-inventory?search=${search || inputValue || ''}&page=${page || 1}${filterss || ''}`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        let res = response.data.data
        setAllItems(res?.response)
        setPageData(res?.pagination)
        setPageLoading(false)
        let pending = {
          completedCount: res?.completedCount,
          pendingBagCount: res?.pendingBagCount,
          pendingValidationCount: res?.pendingValidationCount,
          pendingVolumeCount: res?.pendingVolumeCount
        }
        setProgressCount(pending)
        setIsFiltered(filterss ? true : false)
        closeMenu()
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getPendingInventoryList(bag_number ? bag_number : '')
  }, [page, bag_number])

  useEffect(() => {
    getUsers()
    getMachines()
    getCamps()
    setValue('prepration_date', new Date())
  }, [])

  const generateBagsArray = (storedData, combinationType, bloodComponents, typeAbbreviations) => {
    let blood = combinationType
      .split(',') // Split combinationType into individual components
      .map(abbreviation => {
        const componentName = Object.keys(typeAbbreviations).find(key => getComponentname(key) === abbreviation)
        const componentDetails = bloodComponents.find(item => item.component === componentName)
        return {
          component: componentDetails?.component || abbreviation,
          volume_range: componentDetails?.volume_range || 'N/A',
          standard_volume: componentDetails?.standard_volume || 'N/A'
          // expiry_days: componentDetails?.expiry_days || 'N/A',
          // expiry_date: componentDetails?.expiry_date.format('YYYY-MM-DD') || 'N/A'
        }
      })

    return {
      bags: storedData.map(data => ({
        blood_bag_id: data.id,
        blood: blood
      }))
    }
  }

  function onSubmit(data) {
    if (selectedBags?.length) {
      const bags = generateBagsArray(selectedBags, data?.combination, bloodComponents, typeAbbreviations)
      let bodyData = {
        prepared_by: data?.prepared_by,
        prepared_machine: data?.prepared_machine,
        prepration_date: data?.prepration_date,
        ...bags
      }
      setLoading(true)
      let url = `blood-process/init`
      AxiosHelper.post(url, bodyData)
        .then(() => {
          toast.success('Blood Processing done successfully')
          reset(defaultValues)
          setCurrentTab(2)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          console.log(error)
          toast.error(error || 'Something went wrong!')
        })
    } else {
      toast.error('Please select Blood Bags to continue')

      return
    }
  }

  const manageCheckBox = (bagId, isChecked) => {
    let storedData = [...selectedBags]
    const existingIndex = storedData.findIndex(item => item.id == bagId?.id)

    if (existingIndex !== -1) {
      // Update the existing entry
      const updatedData = selectedBags.filter(item => item.id !== bagId?.id)
      setSelectedBags(updatedData)
      return
    } else {
      // Add a new object to the array
      storedData.push({ ...bagId, isChecked })
    }

    setSelectedBags(storedData)
  }

  const updateAll = useCallback(
    debouncing(searchValue => {
      getPendingInventoryList(searchValue)
    }, 500),
    []
  )

  const resetFilterMain = () => {
    setCamp('')
    setBagType(null)
    setSearchSite('')
    setSearchDateFrom('')
    setSearchDateTo('')
    getPendingInventoryList(bag_number ? bag_number : '', '')
    setCurrSearchPrams(null)
  }

  function handleApplyFilter() {
    let filterurl = ''

    if (searchSite?.length) {
      filterurl = filterurl + `&site=${searchSite}`
    }
    if (searchSite === 'camp' && camp) {
      filterurl = filterurl + `&camp_id=${camp?.id}`
    }
    if (bagType?.value) {
      filterurl = filterurl + `&bag_type=${bagType?.value}`
    }

    if (searchDateFrom?.length) {
      filterurl = filterurl + `&startDate=${searchDateFrom}`
    }
    if (searchDateTo?.length) {
      filterurl = filterurl + `&endDate=${searchDateTo}`
    }

    setCurrSearchPrams({
      searchSite: searchSite,
      searchDateFrom: searchDateFrom,
      searchDateTo: searchDateTo,
      bagType: bagType,
      camp: camp
    })

    getPendingInventoryList(bag_number ? bag_number : '', filterurl)
  }

  useEffect(() => {
    if (isFiltered && closefltr) {
      handleApplyFilter()
    }
    setClosefltr(false)
  }, [bagType, searchSite, searchDateFrom, searchDateTo, camp])

  return (
    <Card sx={{ mt: 2, p: 2 }}>
      <Loader isLoading={isLoading} />
      <FormProvider className='w-100' methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} alignItems='flex-start'>
          <Grid item xs={12} sm={6} md={2.5}>
            <FormControl fullWidth>
              <FormLabel sx={labelStyles}>
                Processing Date/Time
                <span className='text-danger'>*</span>
              </FormLabel>
              <RHFDateTimePicker
                size='small'
                type='text'
                ampm={false}
                fullWidth
                placeholder='Select Date & Time'
                name='prepration_date'
                sx={inputStyles}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <FormControl fullWidth>
              <FormLabel sx={labelStyles}>
                Equipment
                <span className='text-danger'>*</span>
                {/* {selectedMachine ? `(Bag Count: ${selectedMachine?.limit})`:""} */}
              </FormLabel>
              <Autocomplete
                size='small'
                sx={{
                  '&.MuiAutocomplete-noOptions': {
                    padding: 0
                  }
                }}
                options={[...allMachines, { name: '+ Add New Machine', isCustom: true }]}
                noOptionsText={
                  <div
                    style={{
                      background: '#214F8A',
                      color: '#fff'
                    }}
                    className='custom-autocomplete__item custom-autocomplete__add-item'
                    onClick={() => setShowModal(true)}
                  >
                    + Add New Machine
                  </div>
                } // Disable the default noOptionsText handling
                renderOption={(props, option) => (
                  <li
                    style={{
                      ...(option?.isCustom && {
                        background: '#214F8A',
                        color: '#fff'
                      })
                    }}
                    {...props}
                  >
                    {option.name}
                  </li>
                )}
                value={
                  watch('prepared_machine')
                    ? (allMachines?.find(option => {
                      return watch('prepared_machine') === option.name
                    }) ?? null)
                    : null
                }
                getOptionLabel={option => option?.name || ''}
                renderInput={params => <TextField {...params} placeholder='Select Equipment' sx={inputStyles} />}
                onChange={(event, value) => {
                  if (value?.isCustom) {
                    // Handle custom "Add New Machine" action
                    setShowModal(true)
                  } else {
                    // Handle regular options
                    setValue('prepared_machine', value?.name)
                    setSelectedMachines(value)
                  }
                }}
              />
            </FormControl>
            {errors.prepared_machine ? (
              <FormHelperText sx={{ color: 'error.main' }}>{errors.prepared_machine?.message}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <FormControl fullWidth>
              <FormLabel sx={labelStyles}>
                Component to be prepared
                <span className='text-danger'>*</span>
              </FormLabel>
              <Autocomplete
                size='small'
                options={menuItems}
                value={watch('combination')}
                getOptionLabel={option => option || ''}
                renderInput={params => <TextField {...params} placeholder='Select Combination' sx={inputStyles} />}
                onChange={(event, value) => {
                  setValue('combination', value)
                }}
              />
            </FormControl>
            {errors.combination ? (
              <FormHelperText sx={{ color: 'error.main' }}>{errors.combination?.message}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <FormControl fullWidth>
              <FormLabel sx={labelStyles}>
                Components Prepared By
                <span className='text-danger'>*</span>
              </FormLabel>

              <Autocomplete
                freeSolo
                autoHighlight
                size='small'
                disableClearable={false}
                options={userData?.length ? userData : []}
                name='prepared_by'
                forcePopupIcon
                isOptionEqualToValue={(option, value) => option === value || option.name === value}
                getOptionLabel={option => (typeof option === 'string' ? option : option.name || '')}
                value={watch('prepared_by')}
                onChange={(event, obj) => {
                  setValue('prepared_by', obj, { shouldValidate: true, shouldDirty: true })
                }}
                onInputChange={(event, val, reason) => {
                  if (reason === 'reset') return
                  setValue('prepared_by', val, { shouldValidate: true, shouldDirty: true })
                }}
                renderInput={params => {
                  return <TextField {...params} sx={inputStyles} />
                }}
              />

              {errors.prepared_by ? (
                <FormHelperText sx={{ color: 'error.main' }}>{errors.prepared_by?.message}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <LoadingButton
              isLoading={loading}
              onClick={() => {
                // updateOnSave()
              }}
              style={{ marginTop: '22px' }}
              title='Process Blood Bags'
            />
          </Grid>
        </Grid>
      </FormProvider>
      {bag_number ? null : (
        <div className='search-and-filter-container justify-start align-items-end mt-2'>
          <div className='search-container add-dnr-search '>
            <input
              className='search-input'
              type='text'
              placeholder='Search By Blood Bag Number'
              value={search}
              onChange={e => {
                setSearch(e.target.value)
                updateAll(e.target.value)
              }}
            />
            <img
              src={'/assets/images/setting-5.svg'}
              id='basic-button'
              style={{ cursor: 'pointer' }}
              onClick={openMenu}
              alt='search-icon'
            />
          </div>
          <div style={{ gap: '10px' }} className='d-flex flex-wrap align-items-center pb-3'>
            {selectedMachine?.limit ? (
              <span className='filter-tab-div '>
                Bag Count:
                {selectedMachine?.limit}
              </span>
            ) : null}
            {selectedMachine?.duration ? (
              <span className='filter-tab-div '>
                Duration:
                {selectedMachine?.duration}
              </span>
            ) : null}
          </div>

          <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={closeMenu}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}

          //TransitionComponent={Slide}
          >
            <div style={{ height: '320px' }} className='fltr-container'>
              <div className='row mx-widthh'>
                <div className='fltrlabel-div'>
                  <FormLabel sx={labelStyles}> Bag Type</FormLabel>
                </div>
                <div className='col-sm-12   col-md-8'>
                  <Autocomplete
                    size='small'
                    options={bagTypeItemsFix}
                    value={bagType}
                    getOptionLabel={option => option?.label || ''}
                    renderInput={params => <TextField {...params} placeholder='Select Bag Type' sx={inputStyles} />}
                    onChange={(event, value) => {
                      setBagType(value)
                    }}
                  />
                </div>
              </div>
              <div className='row mx-widthh'>
                <div className='fltrlabel-div'>
                  <FormLabel sx={labelStyles}>Donation Site/Place</FormLabel>
                </div>
                <div className='col-md-8'>
                  <Select
                    sx={{
                      ...inputStyles,
                      lineHeight: '2em'
                    }}
                    fullWidth
                    select
                    size='small'
                    placeholder='Enter District'
                    name='site'
                    value={searchSite}
                    onChange={e => setSearchSite(e.target.value)}
                  >
                    <MenuItem value='inhouse'>Blood Bank</MenuItem>
                    <MenuItem value='camp'>Blood Camp</MenuItem>
                  </Select>
                </div>
              </div>
              {searchSite === 'camp' ? (
                <div className='row mx-widthh'>
                  <div className='fltrlabel-div'>
                    <FormLabel sx={labelStyles}>Camp Name</FormLabel>
                  </div>
                  <div className='col-md-8'>
                    <Autocomplete
                      size='small'
                      options={campList}
                      value={camp}
                      // getOptionLabel={option => option?.label || ''}
                      getOptionLabel={option =>
                        option?.camp_name
                          ? `${option.camp_name}${option.camp_schedule_date ? ` (${dayjs(option.camp_schedule_date).format('DD/MM/YYYY')})` : ''}`
                          : ''
                      }
                      renderOption={(props, option) => {
                        return campList.length ? (
                          <li {...props}>
                            {option?.camp_name}
                            {option?.camp_schedule_date
                              ? `(${dayjs(option?.camp_schedule_date).format('DD/MM/YYYY')})`
                              : null}
                          </li>
                        ) : null
                      }}
                      renderInput={params => <TextField {...params} placeholder='Select Camp' sx={inputStyles} />}
                      onChange={(event, value) => {
                        setCamp(value)
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div className='row mx-widthh'>
                <div className='fltrlabel-div'>
                  <FormLabel sx={labelStyles}>Date</FormLabel>
                </div>
                <div className='col-md-4 col-sm-12 inpt'>
                  <FormLabel>From</FormLabel>
                  <DatePicker
                    sx={inputStyles}
                    fullWidth
                    type='date'
                    disableFuture
                    format='DD/MM/YYYY'
                    name='startdate'
                    value={searchDateFrom}
                    onChange={value => setSearchDateFrom(dayjs(value).format('YYYY-MM-DD'))}
                  />
                </div>
                <div className='col-md-4 col-sm-12 inpt'>
                  <FormLabel>To</FormLabel>

                  <DatePicker
                    sx={inputStyles}
                    fullWidth
                    type='date'
                    disableFuture
                    format='DD/MM/YYYY'
                    name='enddate'
                    value={searchDateTo}
                    onChange={value => setSearchDateTo(dayjs(value).format('YYYY-MM-DD'))}
                  />
                </div>
              </div>
              <div className='row pb-4'>
                <div className='fltraction d-flex justify-content-end'>
                  <button className='btn backBtn ' onClick={resetFilterMain}>
                    Reset
                  </button>
                  <LoadingButton title={'Apply Filter'} onClick={handleApplyFilter} isLoading={isLoading} />
                </div>
              </div>
            </div>
          </Menu>
        </div>
      )}

      <Collapse in={isFiltered}>
        <div style={{ gap: '10px' }} className='d-flex flex-wrap align-items-center pb-3'>
          {currSearchPrams?.searchSite ? (
            <span className='filter-tab-div'>
              {currSearchPrams?.searchSite === 'camp' ? 'Blood Camp' : 'Blood Bank'}
              <HighlightOffIcon
                sx={{
                  width: '24px',
                  height: '24px',
                  color: '#214F8A'
                }}
                onClick={() => {
                  setSearchSite('')
                  setClosefltr(true)
                }}
              />
            </span>
          ) : null}
          {currSearchPrams?.camp ? (
            <span className='filter-tab-div'>
              {currSearchPrams?.camp?.camp_name}
              <HighlightOffIcon
                sx={{
                  width: '24px',
                  height: '24px',
                  color: '#214F8A'
                }}
                onClick={() => {
                  setCamp('')
                  setClosefltr(true)
                }}
              />
            </span>
          ) : null}
          {currSearchPrams?.bagType ? (
            <span className='filter-tab-div'>
              {currSearchPrams?.bagType?.label}
              <HighlightOffIcon
                sx={{
                  width: '24px',
                  height: '24px',
                  color: '#214F8A'
                }}
                onClick={() => {
                  setBagType(null)
                  setClosefltr(true)
                }}
              />
            </span>
          ) : null}

          {currSearchPrams?.searchDateFrom ? (
            <span className='filter-tab-div'>
              {dayjs(currSearchPrams?.searchDateFrom).format('DD/MM/YYYY')}

              <HighlightOffIcon
                sx={{
                  width: '24px',
                  height: '24px',
                  color: '#214F8A'
                }}
                onClick={() => {
                  setSearchDateFrom('')
                  setClosefltr(true)
                }}
              />
            </span>
          ) : null}

          {searchDateTo?.length ? (
            <span className='filter-tab-div'>
              {dayjs(searchDateTo).format('DD/MM/YYYY')}
              <HighlightOffIcon
                sx={{
                  width: '24px',
                  height: '24px',
                  color: '#214F8A'
                }}
                onClick={() => {
                  setSearchDateTo('')
                  setClosefltr(true)
                }}
              />
            </span>
          ) : null}

          {currSearchPrams?.searchSite?.length ||
            currSearchPrams?.searchDateFrom?.length ||
            currSearchPrams?.searchDateTo?.length ||
            currSearchPrams?.bagType?.length ||
            currSearchPrams?.camp?.length ? (
            <>
              <div className='filter-tab-cont'>
                <span className='filter-tab-div'>Total Records: {pageData?.total_record || '0'}</span>
              </div>
              <div
                style={{
                  color: '#214F8A',
                  fontSize: '1rem',
                  cursor: 'pointer'
                }}
                onClick={resetFilterMain}
              >
                Clear Filter
              </div>
            </>
          ) : null}
        </div>
      </Collapse>
      <div className='table-responsive mt-2'>
        <ScrollBar>
          <TableContainer className='table table-hover table-element-center'>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ maxWidth: 60, fontWeight: 'bold' }}>
                    {' '}
                    {/* <Checkbox
                      sx={{ p: 0 }}
                      checked={selectAll || selectedBagIds?.length === allItems?.length}
                      onChange={handleSelectAllChange}
                    /> */}
                    {selectedBags?.length ? `${selectedBags?.length} Selected` : null}
                  </TableCell>
                  <TableCell>Blood Bag Info</TableCell>
                  <TableCell sx={{ maxWidth: '90px' }}>Segment No.</TableCell>
                  <TableCell sx={{ maxWidth: '90px' }}>Blood Group</TableCell>
                  <TableCell>Collection Date & Time</TableCell>
                  {/* <TableCell style={{ maxWidth: 170 }}>Select Component to be prepared</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedBags &&
                  selectedBags.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell
                        style={{ maxWidth: 60 }}
                        sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', fontWeight: 'bold' } }}
                      >
                        <Checkbox
                          sx={{ p: 0 }}
                          checked={selectedBags.find(item => item.id === row.id)?.isChecked || false}
                          onChange={e => {
                            let length = selectedMachine?.limit || 12
                            if (selectedBags?.length < length || e.target.checked === false) {
                              manageCheckBox(row, e.target.checked)
                            } else {
                              toast.error('Only 6 Blood Bags are allowed at a time')
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', fontWeight: 'bold' } }}
                      >
                        <ProfileBagRedirect
                          bag_id={row?.BloodBagStageGroupingEntity?.blood_bag_id}
                          bag_number={row?.bag_number}
                        >
                          {row?.bag_number || '-'}
                          <div className='primary-request-text'>{row?.bag_type || '-'}</div>
                        </ProfileBagRedirect>
                      </TableCell>
                      <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                        {row?.tube_number || '-'}
                      </TableCell>
                      <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                        {row?.temp_blood_group ? (
                          <BloogGroupPrintRH bloodGroup={row?.temp_blood_group} />
                        ) : (
                          // bloodGroupApiToUser[row?.temp_blood_group]
                          '-'
                        )}
                      </TableCell>
                      <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                        <div style={checkTimeGap(row?.assigned_date, dayjs(), 6) ? dangerTextStyles : {}}> {row?.assigned_date ? dayjs(row?.assigned_date).format('DD-MM-YYYY HH:mm') : '-'}</div>      
                      </TableCell>
                      {/* <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                        <Select
                          className='select_combination'
                          disabled={
                            !storedCombData.find(item => item.bagId === row.id)?.isChecked || row?.bag_type === 'SDP'
                          }
                          sx={inputStyles}
                          fullWidth
                          clearable
                          size='small'
                          value={storedCombData.find(item => item.bagId === row.id)?.combinationType || ''}
                          onChange={e => {
                            manageBagCombination(row?.id, true, e.target.value)
                          }}
                        >
                          {menuItems?.length
                            ? menuItems?.map((modee, i) => (
                              <MenuItem key={i} value={modee}>
                                {modee || ''}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </TableCell> */}
                    </TableRow>
                  ))}
                {allItems &&
                  allItems
                    ?.filter(row => !selectedBags?.some(selected => selected.id == row.id))
                    ?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell
                          style={{ maxWidth: 60 }}
                          sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', fontWeight: 'bold' } }}
                        >
                          <Checkbox
                            sx={{ p: 0 }}
                            checked={selectedBags?.find(item => item.bagId === row.id)?.isChecked || false}
                            onChange={e => {
                              if (!selectedMachine) {
                                toast.error('Please Select the Equipment First!')

                                return
                              }
                              let length = selectedMachine?.limit || 12
                              if (selectedBags?.length < length || e.target.checked === false) {
                                manageCheckBox(row, e.target.checked)
                              } else {
                                toast.error('Only 6 Blood Bags are allowed at a time')
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important', fontWeight: 'bold' } }}
                        >
                          <ProfileBagRedirect
                            bag_id={row?.BloodBagStageGroupingEntity?.blood_bag_id}
                            bag_number={row?.bag_number}
                          >
                            {row?.bag_number || '-'}
                            <div className='primary-request-text'>{row?.bag_type || '-'}</div>
                          </ProfileBagRedirect>
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          {row?.tube_number || '-'}
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                          {row?.temp_blood_group ? (
                            <BloogGroupPrintRH bloodGroup={row?.temp_blood_group} />
                          ) : (
                            // bloodGroupApiToUser[row?.temp_blood_group]
                            '-'
                          )}
                        </TableCell>
                        <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                        <div style={checkTimeGap(row?.assigned_date, dayjs(), 6) ? dangerTextStyles : {}}> {row?.assigned_date ? dayjs(row?.assigned_date).format('DD-MM-YYYY HH:mm') : '-'}</div>      
                        </TableCell>
                        {/* <TableCell sx={{ '&.MuiTableCell-root': { verticalAlign: 'middle !important' } }}>
                        <Select
                          className='select_combination'
                          disabled={
                            !storedCombData.find(item => item.bagId === row.id)?.isChecked || row?.bag_type === 'SDP'
                          }
                          sx={inputStyles}
                          fullWidth
                          clearable
                          size='small'
                          value={storedCombData.find(item => item.bagId === row.id)?.combinationType || ''}
                          onChange={e => {
                            manageBagCombination(row?.id, true, e.target.value)
                          }}
                        >
                          {menuItems?.length
                            ? menuItems?.map((modee, i) => (
                              <MenuItem key={i} value={modee}>
                                {modee || ''}
                              </MenuItem>
                            ))
                            : null}
                        </Select>
                      </TableCell> */}
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollBar>
      </div>

      {allItems?.length === 0 && (
        <div className='dnr-no-data-found'>
          <NoDataFound title='No Record found' />
        </div>
      )}
      <div className='d-flex justify-content-end mt-2'>
        <PaginationLink pagination={pageData} getNext={page => setPage(page)} />
      </div>

      <AddMachine getStocks={getMachines} openModal={showModal} setOpenModal={setShowModal} isLoading={isLoading} />
    </Card>
  )
}

export default AddSegment
