import React from 'react'
import { useAppContext } from '../../AppContext'

export const ComponentLabelsCompFn = (component, shortName = false) => {
  const componentLables = JSON.parse(localStorage.getItem('componentLables'))

  if (!component) return null
  if (componentLables && componentLables?.length === 0) return component

  const actualPrint = componentLables?.find(item => item?.value === component)

  if (actualPrint && shortName) {
    return actualPrint?.short_name
  }

  if (actualPrint) {
    return actualPrint?.label
  }

  return component
}

const ComponentLabelsComp = ({ component }) => {
  const { componentLables } = useAppContext()

  if (!component) return null
  if (componentLables?.length === 0) return component

  const actualPrint = componentLables?.find(item => item?.value === component)

  if (actualPrint) {
    return actualPrint?.label
  }

  return component
}

export default ComponentLabelsComp
