import moment from 'moment'
import React from 'react'
import BloogGroupPrintRH from '../../../shared/BloogGroupPrintRH'
import { ProfileBagRedirect } from '../../../donors/profile/utils/shared/profile-bag-redirect'

const ViewAssignPlasma = ({ data, info }) => {
  console.log('view plasma --> ', data, info)
  return (
    <div className='table-responsive'>
      <table className='table  table-hover'>
        <thead>
          <tr className='table_header'>
            <th>Bag No.</th>
            <th>Blood Group</th>
            <th>Expiry Date</th>
            <th>No. of Days left</th>
          </tr>
        </thead>
        <tbody className='inventory_tab'>
          {data?.length > 0
            ? data?.map((item, i) => (
                <tr key={i}>
                  {/* <td>{item?.issue_number || ""}</td> */}
                  <td><ProfileBagRedirect  bag_id={item?.blood_bag_id} bag_number={item?.BloodBagIssueEntity?.bag_number}> {item?.BloodBagIssueEntity?.bag_number || '-'}</ProfileBagRedirect></td>
                  <td>
                    {item?.type ? <BloogGroupPrintRH bloodGroup={item?.type} /> : '-'}
                    {/* {item?.type || '-'} */}
                  </td>
                  <td>{item?.expiry_date ? moment(item?.expiry_date).format('DD-MMM-YYYY') : '-'}</td>
                  <td>{item?.expiry_date ? moment(item?.expiry_date).diff(moment(), 'days') : '-'}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  )
}

export default ViewAssignPlasma
