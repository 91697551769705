import '../ui/style.css'
// Helper Component for Info Items
export const InfoItem = ({ title, value, children, is_bulk = false, img,actionCallBack }) => (
  <div className='pd-ib-item1'>
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
        flexDirection: 'column'
      }}
      className='flex-bag'
    >
      <div className='cont-heading'>{title}
         {img ?
        <img
          src={'/assets/images/donor_profile/editbtn.svg'}
          alt='edit'
          className='edit'
          style={{ cursor: 'pointer', width: '18px', height: '18px',marginLeft:'10px' }}
          name='edit'
          onClick={actionCallBack}
        />:null}</div>
      <div className='general-black'>{!is_bulk ? value || children : '-'}</div>
    </div>
  </div>
)
