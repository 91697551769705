import moment from 'moment'
import PrintComponentsView from '../../printComponents/PrintComponentsView'
import { InfoItem } from '../shared/info-item'
import ModalCustom from '../../../../shared/ModalCustom'
import { CardHeader } from '../shared/card-header'
import LoadingButton from '../../../../shared/LoadingButton'
import { useEffect, useState } from 'react'
import AxiosHelper from '../../../../../helper/AxiosHelper'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { downLoadFiles } from '../../../../middleware/auth'
import { BloogGroupPrintRHFn } from '../../../../shared/BloogGroupPrintRH'
import { StatusCapsule } from '../shared/status-capsule'
import { EditCampInfo } from './component/edit-camp-info'
import BloodBagEdit from './component/BloodBagEdit'
import ModalCustom2 from '../../../../shared/ModalCustom2'
import { set } from 'date-fns'

const getFormattedDate = data => {
  if (data?.assigned_date) {
    return moment(data.assigned_date).format('DD MMM YYYY hh:mm A')
  } else if (data?.screening_date) {
    return moment(data.screening_date).format('DD MMM YYYY hh:mm A')
  } else if (data?.createdAt) {
    return moment(data.createdAt).format('DD MMM YYYY hh:mm A')
  } else {
    return '-'
  }
}

export const BloodBagInfo = ({
  trailData,
  setPrintOptions,
  setOpenModal,
  openModal,
  basicData,
  printOptions,
  setCurrentData,
  info,
  setInfo,
  getDonorHistory,
  data
}) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [btnLoadingType, setBtnLoadingType] = useState(false)
  const [donType, setDonType] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editBagModal, setEditBagModal] = useState(false)
  const [bagDeleteModal, setBagDeleteModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (data) {
      let DONATION_TYPE
      if (data?.camp_id === null && data?.DonorScreening === null && !data?.pending_registration) {
        DONATION_TYPE = 'Bulk Receive'
      } else if (data?.camp_id !== null) {
        DONATION_TYPE = 'Camp'
      } else {
        DONATION_TYPE = 'InHouse'
      }
      setDonType(DONATION_TYPE)
    }
  }, [data])

  const handleCloseModal = () => {
    setPrintOptions('')
    setOpenModal(false)
  }
  function findSmsStatusObj(array, type) {
    let objj = array?.find(item => {
      return item.type === type
    })

    if (objj) return objj

    return null
  }

  const sendSMSAgain = (bag_id, type) => {
    setBtnLoading(bag_id)
    setBtnLoadingType(type)
    AxiosHelper.get(`sms-log/send-sms-via-bag/${bag_id}/${type}`)
      .then(response => {
        getDonorHistory()
        setBtnLoading(null)
        setBtnLoadingType(null)
        toast.success('Report Sent Successfully')
      })
      .catch(error => {
        console.log(error)
        setBtnLoading(null)
        setBtnLoadingType(null)
        toast.error(error || 'Something went wrong on our side')
      })
  }

  const updateCampInfo = async (camp_id, bag_id, new_date) => {
    const { data } = await AxiosHelper.post(`donor-v2/change-donor-camp`, { camp_id, bag_id, new_date })
    return data
  }
  const saveEditModal = async ({ camp_id, bag_id, new_date, abha_id, donation_type }) => {
    try {
      if (camp_id && id) {
        let updated_camp_id = camp_id !== "in_house" ? camp_id : null
        const dt = new Date()
        const { data } = await updateCampInfo(updated_camp_id, bag_id ? bag_id : id, dt)
        if (!data) {
          throw new Error('No data found')
        } else {
          getDonorHistory(id)
          toast.success('Blood Camp updated successfully')
        }

      } else {
        throw new Error('Invalid data')
      }
    } catch (error) {
      console.log(error)
      toast.error(error || 'Something went wrong')
    } finally {
    }
  }

  const actionCallBack = async e => {
    setLoading(true)
    if (e.target.name === 'delete') {
      setBagDeleteModal(true)      
    }
    if (e.target.name === 'edit') {
      setEditBagModal(true)
      // setOpenEditModal(true)
    }
    setLoading(false)
  }

  const confirmDelete =() => {
    setLoading(true)
    AxiosHelper.put(`donor-v2/${id}/bag`, {})
      .then(response => {
        setBagDeleteModal(false)
        toast.success('Blood Bag Details Successfully Deleted!')
        setLoading(false)
        history.goBack()
       // getDonorHistory(id)
      })
      .catch(error => {
        setLoading(false)
        setBagDeleteModal(false)
        toast.error(error || 'Fail To Delete The Blood Bag!')
        console.log(error)
      })
  }
  const handleEditDetails = (url,bodyData) => {
    AxiosHelper.put(url,bodyData)
    .then(res => {
        toast.success('Bag number edited successfully')
        getDonorHistory(id)
    })
    .catch(error => {
        console.log('error', error)
        toast.error(error || 'Bag edit failed please try again later')
    })
  }
  return (
    <>
      <ModalCustom
        // confirmDialog={saveEditModal}
        closeDialog={() => setIsOpen(false)}
        title={'Edit Camp Details'}
        open={isOpen}
      >
        <EditCampInfo
          showDonationType={false}
          data={data}
          confirmDialog={saveEditModal}
          closeDialog={() => setIsOpen(false)}
        />
      </ModalCustom>
      <ModalCustom
        // confirmDialog={saveEditModal}
        closeDialog={() => setEditBagModal(false)}
        title={'Edit Blood Bag Details'}        
        open={editBagModal}
        size='md'
      >
        <BloodBagEdit
          showDonationType={false}
          data={data}
          confirmDialog={saveEditModal}
          handleEditDetails={handleEditDetails}
          closeDialog={() => setEditBagModal(false)}
        />
      </ModalCustom>
      <ModalCustom2 
      open={bagDeleteModal} 
      closeDialog={() => setBagDeleteModal(false)} 
      title={`Delete blood bag-${data?.bag_number || ""} ?`} 
      confirmDialog={confirmDelete}
      >
        Deleting this blood bag will remove all related data. Are you sure?
      </ModalCustom2>
      <CardHeader lastUpdated={data?.updatedAt}
        actionBtns
        actionCallBack={actionCallBack}
        // disableEdit={disableEdit}
        editToolTip={"Edit Blod Bag Details"}
      >
        {/* {`Blood Bag ${data?.bag_number ? `- ${data?.bag_number}` : ''}`} */}
        <span>Blood Bag </span>
        <span>{data?.bag_number ? <span>- {data?.bag_number}</span> : null}</span>
      </CardHeader>
      <div className='bag-wrap'>
        {/* Donor Date & Token ID */}
        <InfoItem title='Collection Date & Time' value={getFormattedDate(data)} />
        {/* <InfoItem title='Token ID' value={data?.id || ''} /> */}
        <InfoItem title='Donor Registeration'>
          {!data?.DonorScreening?.PatientDonorEntity && data?.pending_registration ? (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/donors-detail/registration?id=${data.id}`)}
              className='pending'
            >
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          ) : data.is_bulk ? "-" :(
            <div className='pending'>Done</div>
          )}
        </InfoItem>

        {/* Medical Screening */}
        <InfoItem is_bulk={!data?.pending_registration && data.is_bulk} title='Medical Screening'>
          {data?.DonorScreening?.completed ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('print_screening_Report')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Done
            </div>
          ) : (
            <div className='pending'>Pending</div>
          )}
        </InfoItem>

        {/* Blood Bag Issue & Number */}
        {/* <InfoItem is_bulk={!data?.pending_registration && data.is_bulk} title='Blood Bag Issue'>
          {data?.bag_id && !data?.pending_registration ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setPrintOptions('print_bloodbag_details')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Issued
            </div>
          ) : data?.is_defered ? (
            <span className='abort'>Abort</span>
          ) : (
            <div className='pending'>Pending</div>
          )}
        </InfoItem> */}
        <InfoItem title='Blood Bag Number' value={data?.bag_number || 'N/A'} />
        <InfoItem title='Blood Bag Type' value={data?.bag_type || 'N/A'} />

        {/* Segment Number */}
        <InfoItem title='Segment Number' value={data?.tube_number || 'N/A'} />
        <InfoItem title='Lot Number' value={data?.lot_number || 'N/A'} />

        {/* Tti Testing */}
        <InfoItem title='TTI Testing'>
          {!data?.BloodTestingEntity ? (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/donors-detail/testing/${data?.abha_id}`)}
              className='pending'
            >
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          ) : !data?.BloodTestingEntity?.is_declined ? (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                if (!data.is_bulk) {
                  setPrintOptions('print_TTI_Report')
                  setOpenModal(true)
                }
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'done'}>Non-Reactive</StatusCapsule>
            </div>
          ) : (
            <StatusCapsule status={'cancel'}>Reactive</StatusCapsule>
          )}
          {/* {data?.BloodTestingEntity?.id || data.is_bulk ? (
            <div
              className='pending'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!data.is_bulk) {
                  setPrintOptions('print_TTI_Report')
                  setOpenModal(true)
                }
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              Done
            </div>
          ) : data?.is_defered ? (
            <div className='abort'>Abort</div>
          ) : (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/donors-detail/testing/${data?.abha_id}`)}
              className='pending'
            >
              Pending
            </div>
          )} */}
        </InfoItem>

        {/* Inventory */}
        <InfoItem title='Inventory'>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data?.BloodLogsEntities?.length > 0 ? (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setPrintOptions('inventory_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'done'}>Done</StatusCapsule>
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                if (data?.abha_id) {
                  history.push(`/donors-detail/donor-inventory/${data?.abha_id}`)
                }
              }}
              className='pending'
            >
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          )}
        </InfoItem>

        {/* Donor Registeration */}

        {/* Site */}
        <InfoItem title='Site' img={donType ==='Bulk Receive' ? false :true} actionCallBack={() => setIsOpen(true)}>
          {donType === 'Camp' ? (
            <div
              style={{
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/blood-camp-management/view/${data?.CampEntity?.id}`)}
              className='pending'
            >
              {donType} {data?.CampEntity?.camp_name ? `- ${data?.CampEntity?.camp_name}` : ''}
            </div>
          ) : (
            donType
          )}
        </InfoItem>

        {/* Donation Site & Certificate Status */}
        {/* <InfoItem
          is_bulk={data.is_bulk}
          title='Donation Site/Place'
          value={data?.DonorCampEntities?.[0]?.CampEntity?.camp_name || data?.TenantsEntity?.name || 'N/A'}
        /> */}
        <InfoItem is_bulk={data.is_bulk} title='Donation Status'>
          {data?.is_defered ? (
            <div
              className='abort'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setPrintOptions('full_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
            </div>
          ) : (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                setPrintOptions('full_detail')
                setOpenModal(true)
              }}
            >
              <img src='/assets/images/pdf.svg' alt='pdf' style={{ borderRadius: 'initial', height: '24px' }} />
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          )}
        </InfoItem>

        {/* Blood Group */}
        <InfoItem title='Blood Grouping' value={data?.BloodTestingEntity?.blood_group || 'N/A'} />

        <InfoItem is_bulk={data.is_bulk} title={'Donation Certificate'}>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data && data?.bag_id ? (
            <div
              className='pending'
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={() => {
                downLoadFiles(`/download/donor-certificate/${data?.id}`, `Donation_Certificate`)
              }}
            >
              {
                <img
                  src={'/assets/images/pdf.svg'}
                  alt='pdf'
                  style={{
                    borderRadius: 'initial',
                    height: '24px'
                  }}
                />
              }
              Get Certificate
            </div>
          ) : (
            <div className='pending' style={{ cursor: 'pointer' }}>
              {
                <img
                  src={'/assets/images/pdf.svg'}
                  alt='pdf'
                  style={{
                    borderRadius: 'initial',
                    height: '24px'
                  }}
                />
              }
              <StatusCapsule status={'pending'}>Pending</StatusCapsule>
            </div>
          )}
        </InfoItem>

        <InfoItem is_bulk={data.is_bulk} title={'Certificate SMS Status'}>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data && data?.bag_id ? (
            <div className='pending'>
              <LoadingButton
                title={
                  findSmsStatusObj(data?.certificateDeliverLogEntities, 'Certificate')?.is_delivered ? 'Resend' : 'Send'
                }
                disabled={!data}
                style={{ height: '2rem' }}
                isLoading={btnLoading == data?.id && btnLoadingType == 'Certificate'}
                onClick={() => {
                  sendSMSAgain(data?.id, 'Certificate')
                }}
              />
            </div>
          ) : (
            'Not Sent'
          )}
        </InfoItem>

        <InfoItem is_bulk={data.is_bulk} title={'TTI SMS Status'}>
          {data?.is_defered ? (
            <StatusCapsule status={'cancel'}>Abort</StatusCapsule>
          ) : data?.BloodTestingEntity && data?.BloodTestingEntity?.id ? (
            <div className='pending'>
              <LoadingButton
                disabled={!data}
                title={findSmsStatusObj(data?.certificateDeliverLogEntities, 'TTI')?.is_delivered ? 'Resend' : 'Send'}
                style={{ height: '2rem' }}
                isLoading={btnLoading == data?.id && btnLoadingType == 'TTI'}
                onClick={() => {
                  sendSMSAgain(data?.id, 'TTI')
                }}
              />
            </div>
          ) : (
            <div className='pending'>
              {/* {<img src={'/assets/images/pdf.svg'}
                              alt="pdf"
                              onClick={() => printAll("print_TTI_Report", "i_frame")}
                              style={{ borderRadius: "initial", height: "24px" }}
                            />} */}
              Pending
            </div>
          )}
        </InfoItem>

        {/* Modal */}
        <ModalCustom open={openModal} fullScreen closeDialog={handleCloseModal}>
          <PrintComponentsView
            printId={printOptions}
            data={{
              ...trailData,
              is_bulk: data.is_bulk
            }}
            info={basicData}
            handlecloseModle={handleCloseModal}
          />
        </ModalCustom>
      </div>
    </>
  )
}
