import React, { useState, useEffect, useRef, useCallback } from 'react'
import Loader from '../shared/Loader'
import AxiosHelper from '../../helper/AxiosHelper'
import PaginationLink from '../pagination/PaginationLink'
import { useHistory } from 'react-router-dom'
import './Donors.css'
import BreadCrumb from '../common/BreadCrumb'
import { Select, Menu, FormLabel, MenuItem, TextField, Checkbox, ListItemText, Collapse } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

import { checkPermission } from '../middleware/auth'
import AllDonorsTable from './AllDonorsTable'
import ScrollCard from '../shared/ScrollCard'
import { labelStyles } from '../certification/EditCertificate'
import TextButton from '../shared/components/TextButton'
import { checkTenantId, debouncing, getLocalStorageItem, setLocalStorageItem } from '../../helper/CommonAPI'
import ExcelAndPdfDownload from '../print-components/ExcelAndPdfDownload'
import { Add } from '@mui/icons-material'
import { createFilter } from 'react-select'
export const radioStyles = {
  '&.MuiCheckbox-indeterminate,&.Mui-checked': {
    color: '#214F8A'
  }
}

export const inputStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',
  height: '36px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },
  '& .MuiInputBase-input': {
    height: 25
  },
  '& .MuiInputBase-root': {
    height: 36
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard'
  },
  {
    path: '/donors',
    label: 'All Donors'
  }
]

const searchSelectBox = {
  borderRadius: '8px 0px 0px 8px',
  background: 'linear-gradient(90deg, #204E89 0.24%, #12B9AA 99.78%)',
  height: 'inherit',
  marginRight: '8px',
  color: '#ffffff',
  width: '238px'
}

export const processArr = [
  { id: "1", name: "Total Deferred" },
  { id: "2", name: "Pending Medical Screening" },
  { id: "3", name: "Pending Assign Blood Bag" },
  { id: "4", name: "Pending TTI Testing" },
  { id: "5", name: "Pending Blood Component Inventory" },
];

export const sortingOnColumnOptions = [
  { label: "Blood Bag Number", value: 'orderByBagNumber' },
  { label: "Donor Name", value: 'orderByName' },
  { label: "Donation Date", value: 'orderByDontionDate' },
  { label: "Donor Id", value: 'orderById' },
  { label: "Aadhaar", value: 'orderByAdhar' },
  { label: "Donation Type", value: 'orderByDonationType' },
  { label: "Mobile Number", value: 'orderByMobile' },
]

const defaultFilters = {
  location: [],
  processStatus: [],
  isDefer: '',
  gender: '',
  site: '',
  searchSelect: 'bag_number',
  donationType: '',
  startDate: '',
  endDate: '',
  camp: '',
  startAge: '',
  endAge: ''
}

const Donors = () => {
  const [data, setData] = useState([]);
  const [campList, setCampList] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [searchbox, setsearchbox] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [custumUrl, setCustomUrl] = useState("")
  const [currentFilter, setcurrentFilter] = useState(1);
  const [anchor, setAnchor] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [searchIsDefer, setSearchIsdefer] = useState("");
  const [searchGender, setSearchGender] = useState("");
  const [searchSite, setSearchSite] = useState("");
  const [searchSelect, setSearchSelect] = useState(getLocalStorageItem("donorSearchBy", "bag_number"))
  const [searchDonationType, setSearchDonationType] = useState("");
  const [searchLocation, setSearchLocation] = useState([]);
  const [searchProcessStatus, setSearchPrecessStatus] = useState([]);
  const [searchDateFrom, setSearchDateFrom] = useState("");
  const [searchDateTo, setSearchDateTo] = useState("");
  const [searchCamp, setSearchCamp] = useState("");
  const [searchAgeFrom, setSearchAgeFrom] = useState("");
  const [searchAgeTo, setSearchAgeTo] = useState("");
  const [currSearchPrams, setCurrSearchPrams] = useState({});
  const [closefltr, setClosefltr] = useState(false);
  const [searchFilters, setSearchFilters] = useState(defaultFilters)
  const [currentFilters, setcurrentFilters] = useState(defaultFilters)
  const [sorting, setSorting] = useState([])
  const resetFilterMain = () => {
    resetFilterValues()
    handleApplyFilter({}, "filters reset", "")
  }

  const resetFilterValues = () => {
    setSearchFilters(defaultFilters)
    setCurrSearchPrams({})
    setsearchbox("");
    setIsFiltered(false)
  }
  const reff = useRef(null)
  const history = useHistory()

  const getDistict = () => {
    AxiosHelper.get('address/district/29')
      .then(response => {
        setDistricts(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getCamps = () => {
    let url = 'donor/camp-list'
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        setCampList(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getDonors = url => {
    setLoading(true)
    url = checkTenantId(url, 'type')
    AxiosHelper.get(url)
      .then(response => {
        setData(response.data.data)
        setPageData(response.data.data.pagination)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getDistict()
    getCamps()
  }, [])

  useEffect(() => {
    handleApplyFilter(currentFilters, "shorting", '')
  }, [sorting])

  useEffect(() => {
    if (currentFilter === 1) {
       setSearchSelect("bag_number");
    }
    resetFilterMain()
  }, [currentFilter]);

  const handleCustomRequests = (page) => {
    handleApplyFilter(currentFilters, "recall", '')
  }

  const openMenu = event => {
    setAnchor(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchor(null)
  }

  const handleApplyFilter = (val, filType, filValue) => {
    setcurrentFilters(val)
    let url = ''
    if (filType === "filter apply" || isFiltered) {
      let filterurl = ''
      if (val?.isDefer?.length) {
        let deftype = 'Fit'
        if (val?.isDefer === 'Partial') {
          deftype = 1
        }
        if (val?.isDefer === 'Permanent') {
          deftype = 2
        }
        filterurl = filterurl + `&isdefer=${deftype}`
      }
      if (val?.gender) {
        filterurl = filterurl + `&gender=${val?.gender}`
      }
      if (val?.site) {
        filterurl = filterurl + `&site=${val?.site}`
      }
      if (val?.camp) {
        filterurl = filterurl + `&camp_id=${val?.camp?.id}`
      }
      if (val?.donationType) {
        filterurl = filterurl + `&donationType=${val?.donationType}`
      }
      if (val?.startDate) {
        filterurl = filterurl + `&startDate=${val?.startDate}`
      }
      if (val?.endDate) {
        filterurl = filterurl + `&endDate=${val?.endDate}`
      }
      if (val?.startAge) {
        filterurl = filterurl + `&startAge=${val?.startAge}`
      }
      if (val?.endAge) {
        filterurl = filterurl + `&endAge=${val?.endAge}`
      }
      if (val?.location?.length > 0) {
        let arr = val?.location.join('-')
        filterurl = filterurl + `&location=${arr}`
      }
      if (filterurl) {
        setIsFiltered(true)
        url = filterurl
      } else { setIsFiltered(false) }
    }
    if (sorting && sorting?.length > 0) {
      if (sorting[0]?.id) {
        url = url + `&${sorting[0]?.id}=${sorting[0]?.desc ? "desc" : "asc"}`
      }
    }
    if (currentFilter) {
      url = url + `&status=${currentFilter}`;
    }
    if ((filType === "search" && filValue?.length > 0) || (searchbox)) {
      url = url + `&key=${searchSelect}&value=${filValue || searchbox}`
    }
    if (filType === "page" && filValue) {
      url = url + `&page=${filValue}`
    }
    closeMenu();
    if (url?.length > 0) {
      var stringWithoutFirstLetter = url.slice(1);
      getDonors(`donor-v2?` + stringWithoutFirstLetter);
    } else {
      getDonors(`donor-v2`);
    }
  }

  const handleChange = (event, object) => {
    const {
      target: { value }
    } = event
    setSearchFilters({
      ...searchFilters,
      searchLocation: typeof value === 'string' ? value.split(',') : value

    })
  }

  const handleLocationCancel = name => {
    let locationname = searchFilters?.location.filter(function (n) {
      return n !== name
    })
    setClosefltr(true)
    setSearchFilters({ ...searchFilters, location: locationname })
  }
  const handleStatusChange = (event, object) => {
    console.log(event)
    const {
      target: { value }
    } = event
    setSearchPrecessStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const handleStatusCancel = i => {
    let pstatus = searchProcessStatus.filter(function (n) {
      return n !== i
    })
    setSearchPrecessStatus(pstatus)
  }

  const updateAll = useCallback(
    debouncing((searchValue) => {
      handleApplyFilter(currentFilters, "search", searchValue)
    }, 500),
    [currentFilters]
  )

  const handleFiltersChange = (e) => {
    setSearchFilters({ ...searchFilters, [e.target.name]: e.target.value })
  }
  const handleFilterCancel = (name, val) => {
    const crFilters = { ...currentFilters, [name]: val }
    setSearchFilters(crFilters)
    handleApplyFilter(crFilters, "filters cancel")
  }
  return (
    <>
      <Loader isLoading={isLoading} />
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />
      <ScrollCard normal>
        <div id='upper_div_filter'>
          <div className="my-auto">
            <div className="donor-inventory-search" >
              <div
                className="search-and-filter-container align-items-center"
                ref={reff}

              >
                <div className="search-container custom-height paddingnone" style={{ paddingLeft: "0 !important" }}>

                  <Select
                    sx={searchSelectBox}
                    value={searchSelect}
                    className='inputSize'
                    onChange={e => {
                      setLocalStorageItem("donorSearchBy", e.target.value)
                      console.log(e.target.value)
                      setSearchSelect(e.target.value);
                      setsearchbox("")
                    }}
                  >
                    <MenuItem value='bag_number'>Search By Blood Bag </MenuItem>
                    {currentFilter !== 1 ? <MenuItem value='name'>Search By Donor Name </MenuItem> : null}
                    {currentFilter !== 1 ? <MenuItem value='adharcard'>Search By Aadhaar</MenuItem> : null}
                    {currentFilter !== 1 ? <MenuItem value='mobile'>Search By Mobile Number</MenuItem> : null}
                  </Select>
                  <input
                    value={searchbox}
                    onChange={e => {
                      resetFilterValues()
                      setsearchbox(e.target.value)
                      updateAll(e.target.value)
                    }}
                    className='search-input inputSize'
                    type='text'
                    placeholder='Search...'
                  />
                  <img
                    src={'../../assets/images/setting-5.svg'}
                    id='basic-button'
                    onClick={openMenu}
                    alt='search-icon'
                  />
                  <Menu
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    onClose={closeMenu}
                    keepMounted
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}

                  //TransitionComponent={Slide}
                  >
                    <div className='fltr-container'>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Is Defered</FormLabel>
                        </div>
                        <div className='col-sm-12   col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='Defer Type'
                            name='isDefer'
                            value={searchFilters?.isDefer}
                            onChange={handleFiltersChange}
                          >
                            <MenuItem value='fit'>Fit</MenuItem>
                            <MenuItem value='Partial'>Partial</MenuItem>
                            <MenuItem value='Permanent'>Permanent</MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Gender</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='Gender'
                            name='gender'
                            value={searchFilters?.gender}
                            onChange={handleFiltersChange}
                          >
                            <MenuItem value='male'>Male</MenuItem>
                            <MenuItem value='female'>Female</MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Donation Site/Place</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='Enter District'
                            name='site'
                            value={searchFilters?.site}
                            onChange={handleFiltersChange}
                          >
                            <MenuItem value='blood_bank'>Blood Bank</MenuItem>
                            <MenuItem value='camp'>Blood Camp</MenuItem>
                          </Select>
                        </div>
                      </div>
                      {searchFilters?.site === 'camp' ? (
                        <div className='row mx-widthh'>
                          <div className='fltrlabel-div'>
                            <FormLabel sx={labelStyles}>Camp Name</FormLabel>
                          </div>
                          <div className='col-md-8'>
                            <Select
                              sx={inputStyles}
                              fullWidth
                              select
                              placeholder='Enter District'
                              name='camp'
                              value={searchCamp?.camp}
                              onChange={handleFiltersChange}
                            >
                              {campList?.length > 0 ? (
                                campList?.map(ele => <MenuItem value={ele}>{ele?.camp_name}</MenuItem>)
                              ) : (
                                <MenuItem value='Others'>Others</MenuItem>
                              )}
                            </Select>
                          </div>
                        </div>
                      ) : null}
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Donation Type</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='Donation type'
                            name='donationType'
                            value={searchFilters?.donationType}
                            onChange={handleFiltersChange}
                          >
                            <MenuItem value='voluntary'>Voluntary Donor</MenuItem>
                            <MenuItem value='replacement'>Replacement Donor</MenuItem>
                            <MenuItem value='autologous'>Autologous Donor</MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Location</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            multiple
                            fullWidth
                            InputLabelProps={{ shrink: false }}
                            value={searchFilters?.location}
                            onChange={e => {
                              handleChange(e)
                            }}
                            // input={<OutlinedInput label="Tag" />}
                            renderValue={selected => selected.join(',')}
                          //MenuProps={inputStyles}
                          >
                            {districts.length > 0 ? (
                              districts.map((dist, i) => (
                                <MenuItem key={i} value={dist?.name}>
                                  <Checkbox sx={radioStyles} checked={searchFilters?.location.indexOf(dist?.name) > -1} />
                                  <ListItemText primary={dist?.name} />
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value=''>No District Found</MenuItem>
                            )}
                          </Select>
                          <div className='filter-tab-cont'>
                            {searchLocation?.length > 0
                              ? searchLocation?.map((n, i) => (
                                <span className='filter-tab-div'>
                                  {n}
                                  <HighlightOffIcon
                                    sx={{
                                      width: '24px',
                                      height: '24px',
                                      color: '#214F8A'
                                    }}
                                    onClick={() => handleLocationCancel(n)}
                                  />
                                </span>
                              ))
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Date</FormLabel>
                        </div>
                        <div className='col-md-4 col-sm-12 inpt'>
                          <DatePicker
                            sx={inputStyles}
                            fullWidth
                            type='date'
                            format='DD/MM/YYYY'
                            name='startdate'
                            value={searchDateFrom}
                            onChange={value => setSearchDateFrom(dayjs(value).format('YYYY-MM-DD'))}
                          />
                        </div>
                        <div className='col-md-4 col-sm-12 inpt'>
                          <DatePicker
                            sx={inputStyles}
                            fullWidth
                            type='date'
                            format='DD/MM/YYYY'
                            name='enddate'
                            value={searchDateTo}
                            onChange={value => setSearchDateTo(dayjs(value).format('YYYY-MM-DD'))}
                          />
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Age Group</FormLabel>
                        </div>
                        <div className='col-md-4 col-sm-12 inpt'>
                          <TextField
                            sx={inputStyles}
                            fullWidth
                            type='number'
                            name='agefrom'
                            value={searchAgeFrom}
                            onChange={e => setSearchAgeFrom(e.target.value)}
                          />
                        </div>
                        <div className='col-md-4 com-sm-12 inpt'>
                          <TextField
                            sx={inputStyles}
                            fullWidth
                            type='number'
                            name='ageto'
                            value={searchAgeTo}
                            onChange={e => setSearchAgeTo(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="row mx-widthh">
                        <div className="fltrlabel-div">
                          <FormLabel sx={labelStyles}>Text</FormLabel>
                        </div>
                        <div className="col-md-8">
                          <TextField
                            sx={inputStyles}
                            fullWidth
                            type="text"
                            placeholder="Enter Text"
                            name="searh_text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div> */}

                      <div className="row pb-4">
                        <div className="fltraction d-flex flex-row-reverse">
                          <button
                            className="btn gradient-btn"
                            onClick={e => handleApplyFilter(searchFilters, "filter apply")}
                          >
                            Apply Filter
                          </button>
                          <button
                            className="btn backBtn "
                            onClick={resetFilterMain}
                          >
                            Reset
                          </button>
                        </div>

                      </div>
                    </div>
                  </Menu>
                </div>
              </div>

              <div style={{ display: "flex", gap: "16px" }}>
                <ExcelAndPdfDownload baseUrl='donor-v2' name="Donor Register" pdf={true} excel={true} type={"donor_register"} sortingOnColumnOptions={sortingOnColumnOptions} />

                {checkPermission('CREATE_DONORS') && (
                  <button
                    type='button'
                    className='btn gradient-btn custom-height'
                    onClick={() => {
                      history.push('/donors/add')
                    }}
                  >
                    <Add /> Donor Registration
                  </button>
                )}
              </div>
            </div>
          </div>

          <Collapse in={isFiltered}>
            <div style={{ gap: '10px' }} className='d-flex flex-wrap align-items-center pb-3'>
              {currentFilters?.isDefer ? (
                <span className='filter-tab-div '>
                  {currentFilters?.isDefer}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('isDefer', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currentFilters?.gender ? (
                <span className='filter-tab-div'>
                  {currentFilters?.gender}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('gender', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currentFilters?.site ? (
                <span className='filter-tab-div'>
                  {currentFilters?.site === 'blood_bank' ? 'Blood Bank' : 'Blood Camp'}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('site', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currentFilters?.camp ? (
                <span className='filter-tab-div'>
                  {currentFilters?.camp?.camp_name}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('camp', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currentFilters?.donationType ? (
                <span className='filter-tab-div'>
                  {currentFilters?.donationType}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('donationType', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}

              {currentFilters?.startDate ? (
                <span className='filter-tab-div'>
                  {dayjs(currentFilters?.startDate).format('DD/MM/YYYY')}

                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('startDate', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}

              {currentFilters?.endDate ? (
                <span className='filter-tab-div'>
                  {dayjs(currentFilters?.endDate).format('DD/MM/YYYY')}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('endDate', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currentFilters?.startAge ? (
                <span className='filter-tab-div'>
                  Age From : {currentFilters?.startAge}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('startAge', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currentFilters?.endAge ? (
                <span className='filter-tab-div'>
                  Age To : {currentFilters?.endAge}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      handleFilterCancel('endAge', '')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              <div className='filter-tab-cont'>
                {currentFilters?.location?.length > 0
                  ? currentFilters?.location?.map((n, i) => (
                    <span className='filter-tab-div'>
                      {n}
                      <HighlightOffIcon
                        sx={{
                          width: '24px',
                          height: '24px',
                          color: '#214F8A'
                        }}
                        onClick={() => handleLocationCancel(n)}
                      />
                    </span>
                  ))
                  : null}
              </div>

              {currentFilters?.gender?.length ||
                currentFilters?.location?.length > 0 ||
                currentFilters?.endAge?.length ||
                currentFilters?.startAge?.length ||
                currentFilters?.startDate?.length ||
                currentFilters?.endDate?.length ||
                currentFilters?.isDefer?.length ||
                currentFilters?.site?.length ||
                currentFilters?.donationType?.length ? (
                <>
                  <div className='filter-tab-cont'>
                    <span className='filter-tab-div'>Total Records: {pageData?.totalRecord || '0'}</span>
                  </div>
                  <div
                    style={{
                      color: '#214F8A',
                      fontSize: '1rem',
                      cursor: 'pointer'
                    }}
                    onClick={resetFilterMain}
                  >
                    Clear Filter
                  </div>
                </>
              ) : null}
            </div>
          </Collapse>
        </div>
        <AllDonorsTable
          data={data}
          currentFilter={currentFilter}
          setcurrentFilter={setcurrentFilter}
          getDonors={handleCustomRequests}
          sorting={sorting}
          setSorting={setSorting}
          isFiltered={isFiltered}
        />
        {pageData && pageData.totalPage > 1 && (
          <div className='float-right mt-1'>
            <PaginationLink
              pagination={pageData}
              getNext={page => {
                handleApplyFilter({...currentFilters,gapeNumber:page}, "page", page)
              }}
            />
          </div>
        )}
      </ScrollCard>
    </>
  )
}

export default Donors
