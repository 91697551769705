import React, { useCallback, useEffect, useState } from 'react'
import BreadCrumb from '../common/BreadCrumb'
import { CircularProgress, FormHelperText, Grid, FormLabel, MenuItem, TextField, IconButton, Box } from '@mui/material'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import AxiosHelper from '../../helper/AxiosHelper'
import RHFTextField from '../shared/RHFTextfield'
import RHFSelect from '../shared/RHFSelect'
import FormProvider from '../shared/FormProvider'
import Loader from '../shared/Loader'
import { checkPermission, getId, getTenatLicense, getTenatName } from '../middleware/auth'
import { LabelDiv } from '../screening/ScreeningDonorDetails'
import UploadBox from '../shared/UploadBox'
import ScrollCard from '../shared/ScrollCard'
import { Close } from '@mui/icons-material'
import { LabelDivPad } from '../blood-camp-management/addstaff/StaffForm'
import { phoneRegExp } from '../abha/components/DonorDetail'
import { useAppContext } from '../../AppContext'
import { checkTenantId } from '../../helper/CommonAPI'

export function getBase64(file, cb) {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}
const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard'
  },
  {
    path: '/',
    label: 'Certification'
  }
]

const FormDivider = ({ title }) => {
  return (
    <Grid className='Donor-details-sections' item xs={12}>
      <div className='detail-header'>{title ? title : ''}</div>
    </Grid>
  )
}

const defaultValues = {
  camp_id: '',
  type: 'bank',
  license_no: '',
  mobile: '',
  logo: null,
  authority: '',
  designation: '',
  signature: null,
  logo_other: null,
  template_id: '1',
  tenant_id: ''
}

let LetterMessage = 'Must be less than 50 characters'

const schema = yup.object().shape({
  type: yup.string(),
  license_no: yup.string().required('License no is required').max(50, LetterMessage),
  designation: yup.string().required('Designation is required'),
  authority: yup.string().required('Authority is required'),
  signature: yup.mixed().required('Signature is required'),
  // logo_other: yup.mixed().when("type", {
  //   is: "camp",
  //   then: () => yup.mixed().required("Camp Logo is required"),
  // }),
  camp_id: yup.string().when('type', {
    is: 'camp',
    then: () => yup.string().required('Camp is required')
  }),
  logo: yup.mixed().required('Logo is required'),
  mobile: yup.string().required('Mobile number is required').matches(phoneRegExp, 'mobile number is not valid')
})

export const labelStyles = {
  fontSize: '0.9rem',
  fontWeight: 600,
  color: 'black',
  whiteSpace: 'nowrap'
}

export const labelStylesSm = {
  fontSize: '0.8rem',
  fontWeight: 600,
  color: 'black',
  whiteSpace: 'nowrap'
}

export const inputStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },
  '& .MuiInputBase-input': {
    height: 30
  },
  '& .MuiInputBase-root': {
    height: 48
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

export const inputStylesSm = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '8px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },
  '& .MuiInputBase-input': {
    height: 13,
    borderRadius: '8px'
  },
  '& .MuiInputBase-root': {
    height: 32
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

const EditCertificate = () => {
  const [isLoading, setLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const { setActiveTutorial } = useAppContext()
  const [data, setData] = useState([])
  const [editData, setEditData] = useState({})
  const [licence_number, setLicenceNumber] = useState('')

  const getSettingsData = () => {
    AxiosHelper.get(`tenants/setting`)
      .then(resp => {
        setLicenceNumber(resp?.data?.data?.licence_number)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const { id, campId, type } = useParams()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  const history = useHistory()

  // ** Hook
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    setFocus,
    formState: { errors }
  } = methods

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a
    }, null)

    if (firstError) {
      setFocus(firstError)
    }
  }, [errors, setFocus])

  const getBloodCampRequests = () => {
    setPageLoading(true)
    let url = `camp/without-pagination`
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        setData(response.data.data)
        setPageLoading(false)
      })
      .catch(error => {
        setPageLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    setActiveTutorial('attachCertificate')

    return () => {
      setActiveTutorial('')
    }
  }, [])

  useEffect(() => {
    getTemplateLists()
    getBloodCampRequests()
    getSettingsData()
  }, [])

  useEffect(() => {
    if (type) {
      setValue('type', type)
    }
  }, [type])

  useEffect(() => {
    if (licence_number) {
      setValue('license_no', licence_number)
    }
  }, [licence_number])

  console.log('signature', errors, watch('logo_other'))

  const onSubmit = async data => {
    setLoading(true)
    let bodyData = { ...data }

    if (bodyData?.camp_id === null || !bodyData?.camp_id) {
      delete bodyData['camp_id']
    }
    bodyData.tenant_id = getId()
    if (watch('logo') instanceof File) {
      bodyData.logo = watch('logo')?.preview
    } else {
      delete bodyData['logo']
    }
    if (watch('signature') instanceof File) {
      bodyData.signature = watch('signature')?.preview
    } else {
      delete bodyData['signature']
    }
    if (watch('signature2') instanceof File) {
      bodyData.signature2 = watch('signature2')?.preview
    } else {
      delete bodyData['signature2']
    }
    if (watch('signature3') instanceof File) {
      bodyData.signature3 = watch('signature3')?.preview
    } else {
      delete bodyData['signature3']
    }

    if (type === 'camp') {
      if (watch('logo_other') instanceof File) {
        bodyData.logo_other = watch('logo_other')?.preview
      } else {
        delete bodyData['logo_other']
      }
    }

    AxiosHelper.post(`camp/certificate`, bodyData)
      .then(response => {
        toast.success('Certificate updated successfully!')
        setLoading(false)
        let url = watch('camp_id') ? `/certification/camp/${watch('camp_id')}` : '/certification'
        history.push(url)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
        toast.error(error || 'Something went wrong our side!')
      })
  }

  const removeSign = async (data, num) => {
    if (num === 2 && !editData?.signature2) {
      setValue('signature2', '')
      setValue('authority2', '')
      setValue('designation2', '')

      return
    }

    if (num === 3 && !editData?.signature3) {
      setValue('signature3', '')
      setValue('authority3', '')
      setValue('designation3', '')

      return
    }

    setLoading(true)
    let bodyData = {
      removed_signature: [data],
      tenant_id: getId(),
      template_id: watch('template_id')
    }

    if (type === 'camp') {
      bodyData.camp_id = watch('camp_id')
    }

    AxiosHelper.post(`camp/certificate`, bodyData)
      .then(response => {
        toast.success('Sign removed successfully!')
        setLoading(false)
        getTemplateLists()
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
        toast.error(error || 'Something went wrong our side!')
      })
  }

  useEffect(() => {
    function fillFormVal() {
      if (campId) {
        setValue('camp_id', campId)
      }
      if (id) {
        setValue('template_id', id)
      }
    }
    fillFormVal()
  }, [campId, id])

  const getTemplateLists = () => {
    setLoading(true)
    AxiosHelper.post(`certificate/certificate-details`, {
      camp_id: campId ? campId : ''
    })
      .then(response => {
        let certificate = response.data.data
        setEditData(certificate)
        setLoading(false)
        if (certificate) {
          reset({
            camp_id: campId ? campId : '',
            license_no: certificate?.license_no || licence_number || getTenatLicense() || '',
            mobile: certificate?.mobile || '',
            mobile2: certificate?.mobile2 || '',
            logo: certificate?.logo || null,
            authority: certificate?.authority || '',
            designation: certificate?.designation || '',
            signature: certificate?.signature || null,
            authority2: certificate?.authority2 || '',
            designation2: certificate?.designation2 || '',
            signature2: certificate?.signature2 || null,
            authority3: certificate?.authority3 || null,
            designation3: certificate?.designation3 || '',
            signature3: certificate?.signature3 || '',
            logo_other: certificate?.logo_other || null,
            template_id: id,
            type: watch('type') || type
          })
        }
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  const handleDrop = useCallback(
    async (acceptedFiles, name) => {
      const file = acceptedFiles[0]

      if (!file.name.match(/\.(svg|SVG|png|PNG)$/)) {
        toast.error('select valid image.')
        return false
      }

      if (file) {
        await getBase64(file, result => {
          const newFile = Object.assign(file, {
            preview: result
          })
          setValue(name, newFile, {
            shouldValidate: true,
            shouldDirty: true
          })
        })
      }
    },
    [setValue]
  )

  if (pageLoading) {
    return <Loader isLoading={true} />
  }

  return (
    <div>
      <BreadCrumb breadcrumbRoute={breadcrumbRoute} />{' '}
      <ScrollCard>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className='bloodbank_action'>
            <p>Certification Detail</p>
          </div>
          <Grid container spacing={3}>
            <FormDivider title='Blood Bank Information' />

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={{ ...labelStyles, textTransform: 'capitalize' }}>
                Choose Blood {type} Name
                <span className='text-danger'>*</span>
              </FormLabel>

              {type === 'bank' ? (
                <TextField
                  // name="patient_id"
                  size='small'
                  value={getTenatName()}
                  fullWidth
                  disabled
                  sx={inputStyles}
                  // InputProps={InputEndProps}
                />
              ) : (
                <RHFSelect
                  name='camp_id'
                  size='small'
                  fullWidth
                  sx={inputStyles}
                  InputProps={{
                    placeholder: 'Select Camp'
                  }}
                >
                  {data?.length > 0 ? (
                    data?.map((item, i) => (
                      <MenuItem key={i} value={item?.id}>
                        {item?.camp_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Camps Found</MenuItem>
                  )}
                </RHFSelect>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>
                License Number <span className='text-danger'>*</span>
              </FormLabel>
              <RHFTextField
                size='small'
                name='license_no'
                placeholder='123456'
                fullWidth
                sx={inputStyles}
                // InputProps={InputEndProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>
                Mobile Number <span className='text-danger'>*</span>
              </FormLabel>
              <RHFTextField
                size='small'
                name='mobile'
                placeholder='Enter Your Mobile number'
                fullWidth
                sx={inputStyles}
                // InputProps={InputEndProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>Mobile Number</FormLabel>
              <RHFTextField
                size='small'
                name='mobile2'
                fullWidth
                placeholder='Enter Your Mobile number'
                sx={inputStyles}
                // InputProps={InputEndProps}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormLabel sx={labelStyles}>
                Upload Blood Bank Logo <span className='text-danger'>*</span>
              </FormLabel>
              <UploadBox file={watch('logo')} onChange={handleDrop} name='logo' sx={{ height: '60px' }} />
              {errors?.logo ? (
                <FormHelperText sx={{ color: 'error.main' }}>{errors?.logo?.message}</FormHelperText>
              ) : null}
            </Grid>
            {type === 'camp' ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormLabel sx={labelStyles}>Upload Blood Camp Logo</FormLabel>
                <UploadBox file={watch('logo_other')} onChange={handleDrop} name='logo_other' sx={{ height: '60px' }} />
                {errors?.logo_other ? (
                  <FormHelperText sx={{ color: 'error.main' }}>{errors?.logo_other?.message}</FormHelperText>
                ) : null}
              </Grid>
            ) : null}

            <FormDivider title='Signatures' />
            <Grid item xs={12}>
              <LabelDivPad title='Signature 1 (Required)'>
                <Grid alignItems='flex-end' container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormLabel sx={labelStyles}>
                      Enter Authority Name
                      <span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFTextField
                      size='small'
                      placeholder='Enter Name'
                      name='authority'
                      fullWidth
                      sx={inputStyles}
                      // InputProps={InputEndProps}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormLabel sx={labelStyles}>
                      Add Authority Designation
                      <span className='text-danger'>*</span>
                    </FormLabel>
                    <RHFTextField
                      size='small'
                      name='designation'
                      placeholder='Enter Designation'
                      fullWidth
                      sx={inputStyles}
                      // InputProps={InputEndProps}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <UploadBox
                      file={watch('signature')}
                      onChange={handleDrop}
                      name='signature'
                      sx={{ height: '60px' }}
                      placeholder=''
                    />

                    {errors?.signature ? (
                      <FormHelperText sx={{ color: 'error.main' }}>{errors?.signature?.message}</FormHelperText>
                    ) : null}
                  </Grid>
                </Grid>
              </LabelDivPad>
            </Grid>
            <Grid item xs={12}>
              <LabelDivPad title='Signature 2 (if any)'>
                <Grid alignItems='flex-end' container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormLabel sx={labelStyles}>Enter Authority Name</FormLabel>
                    <RHFTextField
                      size='small'
                      name='authority2'
                      placeholder='Enter Name'
                      fullWidth
                      sx={inputStyles}
                      // InputProps={InputEndProps}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormLabel sx={labelStyles}>Add Authority Designation</FormLabel>
                    <RHFTextField
                      size='small'
                      name='designation2'
                      placeholder='Enter Designation'
                      fullWidth
                      sx={inputStyles}
                      // InputProps={InputEndProps}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <UploadBox
                      file={watch('signature2')}
                      onChange={handleDrop}
                      name='signature2'
                      sx={{ height: '60px' }}
                      placeholder=''
                    />
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: {
                        xs: 'center',
                        md: 'flex-start'
                      },
                      alignItems: 'center'
                    }}
                  >
                    <IconButton
                      color='error'
                      sx={{
                        display: watch('signature2') ? 'flex' : 'none',
                        width: 'fit-content',
                        borderRadius: '8px'
                      }}
                      onClick={() => removeSign('signature2', 2)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Grid>
              </LabelDivPad>
            </Grid>
            <Grid item xs={12}>
              <LabelDivPad title='Signature 3 (if any)'>
                <Grid alignItems='flex-end' container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormLabel sx={labelStyles}>Enter Authority Name</FormLabel>
                    <RHFTextField
                      size='small'
                      name='authority3'
                      placeholder='Enter Name'
                      fullWidth
                      sx={inputStyles}
                      // InputProps={InputEndProps}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FormLabel sx={labelStyles}>Add Authority Designation</FormLabel>
                    <RHFTextField
                      size='small'
                      name='designation3'
                      fullWidth
                      placeholder='Enter Designation'
                      sx={inputStyles}
                      // InputProps={InputEndProps}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <UploadBox
                      file={watch('signature3')}
                      onChange={handleDrop}
                      name='signature3'
                      sx={{ height: '60px' }}
                      placeholder=''
                    />
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: {
                        xs: 'center',
                        md: 'flex-start'
                      },
                      alignItems: 'center'
                    }}
                  >
                    <IconButton
                      color='error'
                      sx={{
                        display: watch('signature3') ? 'flex' : 'none',
                        width: 'fit-content',
                        borderRadius: '8px'
                      }}
                      onClick={() => removeSign('signature3', 3)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Grid>
              </LabelDivPad>
            </Grid>

            <Grid item xs={12} display={'flex'} gap={'10px'}>
              <button
                type='button'
                className='btn backBtn '
                onClick={() => {
                  history.goBack()
                }}
              >
                Cancel
              </button>
              {checkPermission('UPDATE_CERTIFICATE') ? (
                <button type='submit' disabled={isLoading} className='btn backBtn btn-gradient-custom '>
                  {isLoading ? <CircularProgress size={30} color='inherit' /> : 'Save'}
                </button>
              ) : null}
            </Grid>
          </Grid>
        </FormProvider>
      </ScrollCard>
    </div>
  )
}

export default EditCertificate
